import type { Notification } from "@circle-react/types/Notification";
import { ActorName } from "./ActorName";
import { DisplayAction } from "./DisplayAction";
import { FallBack } from "./FallBack";
import { NotifiableTitle } from "./NotifiableTitle";

export interface SelectorProps {
  notification: Notification;
  textStructure: string;
}

export const Selector = ({ notification, textStructure }: SelectorProps) => {
  switch (textStructure) {
    case "actor_name":
      return <ActorName notification={notification} />;
    case "display_action":
      return <DisplayAction notification={notification} />;
    case "notifiable_title":
      return <NotifiableTitle notification={notification} />;
    default:
      return <FallBack textStructure={textStructure} />;
  }
};
