import type { ReactNode } from "react";
import { Typography } from "@circle-react-uikit/Typography";

export interface HeadlineTitleProps {
  children: ReactNode;
}

export const HeadlineTitle = ({ children }: HeadlineTitleProps) => (
  <Typography.LabelMd weight="semibold">{children}</Typography.LabelMd>
);
