import PropTypes from "prop-types";
import classNames from "classnames";
import { Table } from "@circle-react-uikit/TableV2";

export const Foot = ({ className, children }) => (
  <Table.TableFoot className={classNames("py-3", className)}>
    {children}
  </Table.TableFoot>
);

Foot.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
