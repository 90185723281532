import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import { TiptapEditor } from "../../TipTap";

export const FormTipTapEditor = ({ name, ...passThroughProps }) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, onBlur } }) => (
        <TiptapEditor
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          {...passThroughProps}
        />
      )}
    />
  );
};

FormTipTapEditor.propTypes = {
  name: PropTypes.string.isRequired,
};
