import { useIsV3 } from "@circle-react/hooks/useIsV3";
import type { NotificationActionType } from "@circle-react/types/Notification";
import type { IconType } from "@circle-react-shared/Icon";
import { Icon, isIconType } from "@circle-react-shared/Icon";

export interface NotificationTypeIconProps {
  action: NotificationActionType;
}

type V3IconType =
  | "16-like-v3"
  | "16-message-v3"
  | "16-mention-v3"
  | "16-post-v3"
  | "16-reply-v3"
  | "16-alert-v3"
  | "16-live-v3";

const getIconType = (action: NotificationActionType): V3IconType | null => {
  switch (action) {
    case "like":
      return "16-like-v3";
    case "message":
      return "16-message-v3";
    case "mention":
      return "16-mention-v3";
    case "add":
      return "16-post-v3";
    case "comment":
      return "16-message-v3";
    case "reply":
      return "16-reply-v3";
    case "flagged":
      return "16-alert-v3";
    case "live_stream_went_live":
      return "16-live-v3";
    default:
      return null;
  }
};

export const NotificationTypeIcon = ({ action }: NotificationTypeIconProps) => {
  const { isV3Enabled } = useIsV3();
  const v2Icon: IconType | null = isIconType(action) ? action : null;
  const v3Icon: V3IconType | null = getIconType(action);

  if (isV3Enabled && v3Icon) {
    return (
      <div className="absolute left-5 top-5 z-0 flex h-4 w-4 items-center justify-center rounded-full text-white">
        <Icon type={v3Icon} size={16} />
      </div>
    );
  }

  if (!isV3Enabled && v2Icon) {
    return (
      <div className="notifications__icon !left-5 !top-5 !h-4 !w-4">
        <Icon type={v2Icon} className="!w-3" size={24} />
      </div>
    );
  }

  return null;
};
