import classNames from "classnames";
import { t } from "i18n-js";
import { useSpotlightSearch } from "@circle-react/components/Modals/SpotlightSearch";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { Icon } from "@circle-react-shared/Icon";
import { useHeaderBorderStyles } from "../../useHeaderColors";

export interface SearchButtonProps {
  isPreviewMode?: boolean;
  isLarge?: boolean;
  isDisabled?: boolean;
}

export const SearchButton = ({
  isPreviewMode = false,
  isLarge = false,
  isDisabled = false,
}: SearchButtonProps) => {
  const spotlightSearch = useSpotlightSearch();
  const styles = useHeaderBorderStyles();
  const { isV3Enabled } = useIsV3();

  const showSpotlightSearch = () => {
    void spotlightSearch.show();
  };

  return (
    <div className="group relative justify-self-center">
      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4">
        <Icon
          type={isV3Enabled ? "20-flux-search" : "20-search-v3"}
          size={20}
          className={classNames(
            "text-c-header group-hover:text-c-header-active group-focus-within:text-c-header-active transition-colors duration-150",
          )}
        />
      </div>
      <button
        type="button"
        disabled={isDisabled}
        className={classNames(
          "border-primary hover:bg-c-header-hover focus-visible:bg-c-header-hover text-c-header hover:text-c-header-active focus:text-c-header-active hover:border-primary focus-visible:!outline-secondary text-xs-plus flex h-9 items-center rounded-full border py-2 pl-10 font-medium transition-colors duration-150 focus-visible:!outline focus-visible:!outline-2 focus-visible:!outline-offset-2",
          {
            "w-32": !isLarge,
            "w-[26.125rem]": isLarge,
          },
        )}
        onClick={() => !isPreviewMode && showSpotlightSearch()}
        style={{ ...styles }}
      >
        {t("search_item")}
      </button>
    </div>
  );
};
