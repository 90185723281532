import { createContext, useContext } from "react";

export const AccordionContext = createContext();
AccordionContext.displayName = "AccordionContext";

const useAccordionContext = () => {
  const context = useContext(AccordionContext);
  if (!context) {
    throw new Error("No context found for Accordion");
  }
  return context;
};

export default useAccordionContext;
