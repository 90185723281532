import { Typography } from "@circle-react-uikit/Typography";
import { KeyboardInput } from "./KeyboardInput";

export interface KeyboardShortcutProps {
  name: string;
  keys: string[];
}

export const KeyboardShortcut = ({ name, keys }: KeyboardShortcutProps) => (
  <div
    key={name}
    className="border-primary flex flex-row items-center justify-between border-b py-2 last:border-0"
    data-testid="keyboard-shortcut-item"
  >
    <Typography.LabelSm>{name}</Typography.LabelSm>
    <KeyboardInput keys={keys} />
  </div>
);
