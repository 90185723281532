import type { ReactNode } from "react";
import classNames from "classnames";

export interface StatusTextProps {
  children: ReactNode;
  variant?: "success" | "warning" | "danger" | "default";
  textTransform?: "uppercase" | "capitalize" | "lowercase" | "normal-case";
}

const fontColors = {
  success: "text-v2-success",
  warning: "text-v2-warning-900",
  danger: "text-v2-danger",
  default: "text-dark",
};

export const StatusText = ({
  children,
  variant = "default",
  textTransform,
}: StatusTextProps) => {
  const fontStyles = fontColors[variant] || fontColors.default;

  return (
    <span className={classNames("text-sm", fontStyles, textTransform)}>
      {children}
    </span>
  );
};
