import PropTypes from "prop-types";
import { flexRender } from "@tanstack/react-table";
import classNames from "classnames";
import { Icon } from "@circle-react-shared/Icon";
import { Table } from "@circle-react-uikit/TableV2";
import { useDataTableDataProvider } from "../Provider/DataTableDataProvider";

export const SortableTableHead = ({
  header,
  ascendingIcon = "12-arrow-up",
  descendingIcon = "12-arrow-down",
}) => {
  const { isLoading } = useDataTableDataProvider();

  if (header.isPlaceholder) return null;

  const sortIcons = {
    asc: <Icon type={ascendingIcon} size={12} className="text-dark" />,
    desc: <Icon type={descendingIcon} size={12} className="text-dark" />,
  };
  const sortIcon = sortIcons[header.column.getIsSorted()] || null;

  const isColumnSortable = !(header.column.columnDef.canSort === false);

  const HeaderText = ({ header }) => (
    <Table.TableHeaderText className={header.column.columnDef.thInnerClassName}>
      {flexRender(header.column.columnDef.header, header.getContext())}
    </Table.TableHeaderText>
  );

  return (
    <Table.Th
      className={classNames(
        {
          "hover:bg-secondary cursor-pointer select-none transition-colors":
            isColumnSortable && !isLoading,
          "opacity-30": isColumnSortable && isLoading,
        },
        header.column.columnDef.thClassName,
      )}
      {...(isColumnSortable &&
        !isLoading && {
          onClick: header.column.getToggleSortingHandler(),
        })}
    >
      {isColumnSortable ? (
        <span className="flex items-center">
          <HeaderText header={header} />
          <span
            className={classNames("flex h-4 w-4 items-center", {
              invisible: Boolean(!sortIcon),
            })}
          >
            {sortIcon}
          </span>
        </span>
      ) : (
        <HeaderText header={header} />
      )}
    </Table.Th>
  );
};

SortableTableHead.propTypes = {
  header: PropTypes.object.isRequired,
  hasSorting: PropTypes.bool,
  ascendingIcon: PropTypes.string,
  descendingIcon: PropTypes.string,
};
