import { useRef } from "react";
import classNames from "classnames";
import { t } from "i18n-js";
import { useHotkeys } from "react-hotkeys-hook";
import { useChatNotification } from "@circle-react/hooks/chatsV2";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { IconButton } from "../../IconButton";
import { BadgeIndicator } from "../BadgeIndicator";

export interface DMsPopoverButtonProps {
  isOpen?: boolean;
}

export const DMsPopoverButton = ({ isOpen = false }: DMsPopoverButtonProps) => {
  const directMessagesButtonRef = useRef<HTMLButtonElement>(null);
  const { unreadChatCount } = useChatNotification();
  const { isV3Enabled } = useIsV3();

  useHotkeys(
    "alt+m",
    () => {
      directMessagesButtonRef.current?.click();
    },
    [directMessagesButtonRef],
  );

  return (
    <div className="relative">
      <TippyV2 interactive={false} content={t("header_v3.direct_messages")}>
        <IconButton
          name={isV3Enabled ? "20-message-v3" : "16-chat"}
          buttonSize={isV3Enabled ? 36 : 28}
          iconSize={isV3Enabled ? 20 : 16}
          className={classNames(
            "hover:!bg-c-header-hover focus:!bg-c-header-hover !rounded-md p-1.5 duration-150",
            {
              "!text-c-header hover:!text-c-header-active focus:!text-c-header-active":
                isV3Enabled,
              "!bg-c-header-active !text-c-header-active":
                isV3Enabled && isOpen,
            },
          )}
          iconClassName={classNames({
            "!text-c-header": !isV3Enabled,
            "!text-inherit !transition-none": isV3Enabled,
          })}
          ariaLabel={t("header_v3.direct_messages")}
          dataTestId="direct-messages-popover-button"
          ref={directMessagesButtonRef}
        />
        {unreadChatCount > 0 && (
          <BadgeIndicator
            count={unreadChatCount}
            dataTestId="unread_direct_messages_count"
          />
        )}
      </TippyV2>
    </div>
  );
};
