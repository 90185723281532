import PropTypes from "prop-types";
import { Popover as PopoverUI, Transition } from "@headlessui/react";
import classNames from "classnames";
import { Icon } from "@circle-react-shared/Icon";
import { popOverInOut } from "../animations";
import "./styles.scss";

export const Popover = ({
  buttonTitle,
  iconType,
  viewBox = "0 0 20 20",
  children,
}) => (
  <PopoverUI className="react-popover">
    {({ open }) => (
      <>
        <PopoverUI.Button
          className={classNames(
            "text-darkest bg-tertiary hover:bg-secondary rounded-lg px-[18px] py-[6px] text-sm font-medium leading-5",
          )}
        >
          {iconType && (
            <Icon
              viewBox={viewBox}
              type={iconType}
              className="react-popover__icon"
            />
          )}
          <span>{buttonTitle}</span>
        </PopoverUI.Button>

        <Transition show={open} {...popOverInOut}>
          <PopoverUI.Panel className="react-popover__panel">
            <div className="react-popover__panel-wrapper">
              <div className="react-popover__panel-inner">{children}</div>
            </div>
          </PopoverUI.Panel>
        </Transition>
      </>
    )}
  </PopoverUI>
);

Popover.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
  iconType: PropTypes.string,
  viewBox: PropTypes.string,
  children: PropTypes.node,
};
