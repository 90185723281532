import classnames from "classnames";
import type { ThProps } from "./interfaces";

export const Th = ({ className, children, ...rest }: ThProps) => (
  <th
    className={classnames(
      "border-primary whitespace-nowrap border-b px-4 pb-3 pt-5 text-left leading-tight first:pl-0 last:pr-0",
      className,
    )}
    {...rest}
  >
    {children}
  </th>
);
