import classNames from "classnames";
import { transformKeysBasedOnOs } from "./utils";

export interface KeyboardInputProps {
  keys: string[];
  isDarkMode?: boolean;
  wrapperClassName?: string;
  className?: string;
  size?: "xs" | "sm";
}

export const KeyboardInput = ({
  keys,
  isDarkMode = false,
  wrapperClassName,
  className,
  size = "sm",
}: KeyboardInputProps) => {
  const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;

  return (
    <div className={classNames("flex flex-row space-x-2", wrapperClassName)}>
      {transformKeysBasedOnOs(isMac, keys).map(key => (
        <span
          key={key}
          className={classNames(
            "rounded-md px-2 py-1 text-center",
            {
              "bg-tertiary text-selector-active": !isDarkMode,
              "bg-toggle-active text-white": isDarkMode,
            },
            {
              "min-h-[24px] min-w-[24px] text-sm": size === "sm",
              "min-h-[16px] min-w-[16px] text-xs": size === "xs",
            },
            className,
          )}
        >
          {key}
        </span>
      ))}
    </div>
  );
};
