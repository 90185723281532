export const NOTIFICATION_ARCHIVED_STATUS = "archived";

export const FOLLOWABLE_NOTIFICATION_TYPES = ["Comment", "Post"];

export const NOTIFICATION_ACTION_MAPPER = {
  markAsRead: "mark_as_read",
  archive: "archive",
  follow: "follow",
  unfollow: "unfollow",
  markAllAsRead: "mark_all_as_read",
} as const;
