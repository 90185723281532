export interface SkeletonLoaderProps {
  rows: number;
}

export const SkeletonLoader = ({ rows }: SkeletonLoaderProps) => (
  <>
    {[...Array(rows)]
      .map((_, index) => index)
      .map(number => (
        <div key={number} className="flex flex-row px-4 py-3">
          <div className="bg-tertiary mr-3 h-8 w-8 animate-pulse rounded-md" />
          <div className="flex w-full flex-col items-start space-y-1">
            <div className="bg-tertiary h-4 w-full animate-pulse rounded-md" />
            <div className="bg-tertiary h-3 w-1/3 animate-pulse rounded-md" />
          </div>
        </div>
      ))}
  </>
);
