import { Typography } from "@circle-react/components/shared/uikit/Typography";
import type { Notification } from "@circle-react/types/Notification";

export interface NotifiableTitleProps {
  notification: Notification;
}

export const NotifiableTitle = ({ notification }: NotifiableTitleProps) => (
  <Typography.BodySm as="span" weight="font-semibold">
    {notification.notifiable_title}
  </Typography.BodySm>
);
