import { invalidateNotificationsQueryCache } from "./useHeaderNotificationsApi";

export interface NotificationReceiveProps {
  eventData: any;
  queryClient: any;
  setNotificationCount: any;
}

export const onNotificationReceive = ({
  eventData,
  queryClient,
  setNotificationCount,
}: NotificationReceiveProps) => {
  switch (eventData.event) {
    case "resetNewNotificationCount":
      setNotificationCount(eventData.new_notifications_count);
      break;
    case "updateNewNotificationCount":
      setNotificationCount(eventData.new_notifications_count);
      invalidateNotificationsQueryCache({ queryClient });
      break;
    case "newNotification":
      setNotificationCount((prev: number) => prev + 1);
      invalidateNotificationsQueryCache({ queryClient });
      break;
  }
};
