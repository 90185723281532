import PropTypes from "prop-types";
import { t } from "i18n-js";
import { Loader as LoaderComponent } from "@circle-react-uikit/Loader";
import { Table } from "@circle-react-uikit/TableV2";
import { Typography } from "@circle-react-uikit/Typography";
import { useDataTableDataProvider } from "../Provider/DataTableDataProvider";

export const Loader = ({ text }) => {
  const { data, columns, isLoading } = useDataTableDataProvider();
  const colSpan = columns.length;
  const hasData = data.length > 0;

  if (isLoading && !hasData) {
    return (
      <Table.TableBody>
        <Table.Tr>
          <Table.Td colSpan={colSpan}>
            {text ? (
              <div className="text-center">
                <Typography.LabelSm color="text-light">
                  {text || t("datatable.empty.no_data")}
                </Typography.LabelSm>
              </div>
            ) : (
              <LoaderComponent center className="!p-0" />
            )}
          </Table.Td>
        </Table.Tr>
      </Table.TableBody>
    );
  }

  return null;
};

Loader.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};
