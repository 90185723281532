import type { ComponentPropsWithoutRef, ReactNode } from "react";
import classnames from "classnames";

export type CellVariant =
  | "primary"
  | "dark"
  | "actions"
  | "no-padding"
  | "no-x-padding"
  | "right"
  | "no-border"
  | "uppercase";

export interface TableCellProps extends ComponentPropsWithoutRef<"td"> {
  children: ReactNode;
  className?: string;
  variant?: CellVariant;
  shouldWrap?: boolean;
}

/** @deprecated Please use the new Table components at "@circle-react-uikit/TableV2" */
export const TableCell = ({
  children,
  className = "",
  variant = "primary",
  shouldWrap = true,
  ...rest
}: TableCellProps) => (
  <td
    className={classnames(
      "border-primary border-b py-5",
      { "whitespace-nowrap": shouldWrap },
      {
        "cursor-pointer": !!rest?.onClick,
        "text-darkest text-sm font-medium": variant === "dark",
        "text-right text-sm font-medium": variant === "actions",
        "text-dark text-sm": variant === "primary",
        "!px-0": variant === "no-x-padding",
        "!p-0": variant === "no-padding",
        "text-right": variant === "right",
        "!border-0 p-0": variant === "no-border",
        "text-dark text-[11px] font-semibold uppercase":
          variant === "uppercase",
      },
      className,
    )}
    {...rest}
  >
    {children}
  </td>
);
