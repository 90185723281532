import { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { isFunction, isUndefined, noop } from "lodash";

const ExpandableBlockData = createContext({
  isOpen: false,
  isDefaultOpen: false,
  shouldCloseOnClickOutside: false,
  onToggle: noop,
});
ExpandableBlockData.displayName = "ExpandableBlockData";

export const useExpandableBlockData = () => useContext(ExpandableBlockData);

export const ExpandableBlockDataProvider = ({
  isOpen: isOpenProp,
  isDefaultOpen,
  onToggle,
  shouldCloseOnClickOutside,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpen);
  const isControlled = !isUndefined(isOpenProp);

  useEffect(() => {
    if (!isControlled) {
      return;
    }
    setIsOpen(isOpenProp);
  }, [isOpenProp, isControlled]);

  const onToggleHandler = () => {
    if (isFunction(onToggle)) onToggle();
    if (isControlled) {
      return;
    }
    setIsOpen(prev => !prev);
  };

  return (
    <ExpandableBlockData.Provider
      value={{
        isOpen,
        isDefaultOpen,
        onToggle: onToggleHandler,
        shouldCloseOnClickOutside,
      }}
    >
      {children}
    </ExpandableBlockData.Provider>
  );
};

ExpandableBlockDataProvider.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isDefaultOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  shouldCloseOnClickOutside: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
