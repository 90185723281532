import type { LessonBookmarkRecord } from "@circle-react/types/Bookmarks";
import { Typography } from "@circle-react-uikit/Typography";

export interface LessonProps {
  bookmark: LessonBookmarkRecord;
}

export const Lesson = ({ bookmark }: LessonProps) => {
  const { title, section, course } = bookmark.bookmark_record ?? {};

  return (
    <div className="flex flex-col gap-2">
      <Typography.LabelXs weight="semibold">
        <span className="line-clamp-2 leading-5">{course}</span>
      </Typography.LabelXs>
      <div className="flex flex-col gap-1">
        <Typography.LabelSm weight="semibold">
          <span className="line-clamp-2 leading-5">{title}</span>
        </Typography.LabelSm>
        <Typography.LabelXs color="text-default">
          <span className="line-clamp-2 leading-5">{section}</span>
        </Typography.LabelXs>
      </div>
    </div>
  );
};
