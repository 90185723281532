import { Container } from "./Container";
import { ExpandableBlockDataProvider } from "./ExpandableBlockDataProvider";

interface ExpandableBlockProps {
  isOpen?: boolean;
  isDefaultOpen?: boolean;
  onToggle?: () => void;
  shouldCloseOnClickOutside?: boolean;
  className?: string;
  children: React.ReactNode;
}

export const ExpandableBlock = ({
  isOpen = false,
  isDefaultOpen,
  onToggle,
  shouldCloseOnClickOutside,
  className,
  children,
}: ExpandableBlockProps) => (
  <ExpandableBlockDataProvider
    isOpen={isOpen}
    isDefaultOpen={isDefaultOpen}
    onToggle={onToggle}
    shouldCloseOnClickOutside={shouldCloseOnClickOutside}
  >
    <Container className={className}>{children}</Container>
  </ExpandableBlockDataProvider>
);
