import type { MouseEvent } from "react";
import classNames from "classnames";
import { t } from "i18n-js";
import { useQueryClient } from "react-query";
import { getCommentHierarchyKey } from "@circle-react/components/CommentsV2/CommentHierarchy/useCommentHierarchy";
import { getCommentsListKey } from "@circle-react/components/CommentsV2/CommentsList/useCommentsList";
import { getCommentHierarchyKey as getCommentHierarchyKeyV3 } from "@circle-react/components/CommentsV3/CommentHierarchy/useCommentHierarchy";
import { getCommentsListKey as getCommentsListKeyV3 } from "@circle-react/components/CommentsV3/CommentsList/useCommentsList";
import { usePunditUserContext } from "@circle-react/contexts";
import { findMatchingQueryKeysForPostId } from "@circle-react/contexts/postsDetailsContext";
import { chatEventsEmitter } from "@circle-react/hooks/chatsV2";
import { useAnyHoverMediaQuery } from "@circle-react/hooks/useMediaQuery";
import type { Bookmark, CommentBookmark } from "@circle-react/types/Bookmarks";
import type { BookmarkType } from "@circle-react/types/Bookmarks";
import { useBookmarks } from "@circle-react-shared/Bookmarks/hooks/useBookmarks";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { Dropdown } from "@circle-react-uikit/Dropdown";

const isCommentBookmark = (bookmark: Bookmark): bookmark is CommentBookmark =>
  "post_id" in bookmark;

export interface ActionsProps {
  bookmarkId: number;
  bookmarkType: BookmarkType;
  recordId: number | null;
  bookmarkRecord: Bookmark | null;
}

export const Actions = ({
  bookmarkId,
  bookmarkType,
  bookmarkRecord,
  recordId,
}: ActionsProps) => {
  const canHover = useAnyHoverMediaQuery();
  const queryClient = useQueryClient();
  const { currentCommunitySettings } = usePunditUserContext();
  const isTailwindPostsEnabled =
    currentCommunitySettings?.tailwind_posts_enabled;
  const refetchCommentList = isTailwindPostsEnabled
    ? getCommentsListKeyV3
    : getCommentsListKey;
  const refetchCommentHierarchy = isTailwindPostsEnabled
    ? getCommentHierarchyKeyV3
    : getCommentHierarchyKey;

  const { resetQuery, deleteBookmark } = useBookmarks({
    bookmarkId,
    bookmarkType,
    onSuccess: () => {
      if (bookmarkType === "post" || bookmarkType === "event") {
        const matchingQueryKeys = findMatchingQueryKeysForPostId({
          postId: recordId,
          queryClient,
        });
        matchingQueryKeys.forEach((key: any) => {
          void queryClient.invalidateQueries(key);
        });
      }
      if (
        bookmarkType === "comment" &&
        bookmarkRecord &&
        isCommentBookmark(bookmarkRecord)
      ) {
        void queryClient.refetchQueries(
          refetchCommentList({
            postId: bookmarkRecord.post_id,
            parentCommentId: bookmarkRecord.parent_comment_id,
          }),
        );
        void queryClient.refetchQueries(
          refetchCommentHierarchy({
            postId: bookmarkRecord.post_id,
            id: bookmarkRecord.id,
          }),
        );
      }
      if (bookmarkType === "message" && recordId) {
        chatEventsEmitter.toggleMessageBookmark(recordId);
      }
      void resetQuery();
    },
  });
  return (
    <Dropdown
      size="medium"
      direction="bottom-end"
      className={classNames("h-6 w-6", {
        "invisible group-hover:visible": canHover,
      })}
      button={
        <IconButton
          name="16-menu-dots-horizontal"
          ariaLabel={t("options_dropdown")}
        />
      }
      transitionWrapperClassName="z-30"
    >
      <Dropdown.ItemLink
        onClick={(e: MouseEvent) => {
          deleteBookmark();
          e.stopPropagation();
        }}
      >
        {t("bookmarks.actions.remove")}
      </Dropdown.ItemLink>
    </Dropdown>
  );
};
