import { DataTableDataProvider } from "./Provider/DataTableDataProvider";
import { Actions } from "./components/Actions";
import { Body } from "./components/Body";
import { Foot } from "./components/Foot";
import { Head } from "./components/Head";
import { Header } from "./components/Header";
import { Headline } from "./components/Headline";
import { Loader } from "./components/Loader";
import { NoRecords } from "./components/NoRecords";
import { Pagination } from "./components/Pagination";
import { ScrollableContainer } from "./components/ScrollableContainer";
import { SortableHead } from "./components/SortableHead";
import { SortableTableHead } from "./components/SortableTableHead";
import { Table } from "./components/Table";
import { TableWrapper } from "./components/TableWrapper";

export const DataTable = Object.assign(DataTableDataProvider, {
  Table,
  TableWrapper,
  Headline,
  Header,
  Head,
  SortableHead,
  Body,
  Foot,
  Pagination,
  ScrollableContainer,
  SortableTableHead,
  Loader,
  NoRecords,
  Actions,
});
