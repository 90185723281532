export const transformKeysBasedOnOs = (isMac: boolean, keys: string[]) =>
  keys.map(key => {
    const stringKey = String(key);
    const lowerCaseKey = stringKey.toLowerCase();

    if (lowerCaseKey === "ctrl") {
      return isMac ? "⌘" : stringKey;
    }

    if (lowerCaseKey === "shift") {
      return isMac ? "⇧" : stringKey;
    }

    if (lowerCaseKey === "alt" || lowerCaseKey === "option") {
      return isMac ? "⌥" : stringKey;
    }

    if (lowerCaseKey === "cmd") {
      return isMac ? "⌘" : "ctrl";
    }

    if (lowerCaseKey === "enter") {
      return "↵";
    }

    return stringKey.toUpperCase();
  });
