import { Table as TableElement } from "./Table";
import { TableBody } from "./TableBody";
import { TableFoot } from "./TableFoot";
import { TableHead } from "./TableHead";
import { TableHeaderText } from "./TableHeaderText";
import { Td } from "./Td";
import { Th } from "./Th";
import { Tr } from "./Tr";

export const Table = Object.assign(TableElement, {
  TableHead,
  TableHeaderText,
  TableBody,
  Th,
  Tr,
  Td,
  TableFoot,
});
