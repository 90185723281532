import type { ComponentPropsWithoutRef, ReactNode } from "react";

export interface TableHeadProps extends ComponentPropsWithoutRef<"thead"> {
  children: ReactNode;
}

/** @deprecated Please use the new Table components at "@circle-react-uikit/TableV2" */
export const TableHead = ({ children, ...rest }: TableHeadProps) => (
  <thead {...rest}>{children}</thead>
);
