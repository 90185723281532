import type { ReactNode } from "react";
import { noop } from "lodash";
import { Link } from "react-router-dom";
import { isIntendedOnNewTab } from "@circle-react/helpers/browserHelpers";
import type { Notification } from "@circle-react/types/Notification";
import { usePopoverPortalContext } from "@circle-react-shared/uikit/PopoverPortal";
import { useNotificationsActions } from "../useHeaderNotificationsApi";
import { NOTIFICATION_ACTION_MAPPER } from "./Item/constants";

export interface NotificationItemWrapperProps {
  children: ReactNode;
  notification: Notification;
}

export const NotificationItemWrapper = ({
  children,
  notification,
}: NotificationItemWrapperProps) => {
  const { onClose = noop } = usePopoverPortalContext() || {};
  const { mutate: performNotificationAction } = useNotificationsActions();

  return (
    <Link
      to={notification.action_web_path}
      onClick={event => {
        performNotificationAction({
          id: notification.id,
          action: NOTIFICATION_ACTION_MAPPER.markAsRead,
        });
        if (isIntendedOnNewTab(event)) {
          return;
        }
        onClose();
      }}
      className="w-full"
    >
      {children}
    </Link>
  );
};
