import { forwardRef } from "react";
import type { ComponentPropsWithoutRef, ReactNode } from "react";
import classnames from "classnames";

export interface TableRowProps extends ComponentPropsWithoutRef<"tr"> {
  children: ReactNode;
  className?: string;
  dataTestId?: string;
  isSelectable?: boolean;
}

/** @deprecated Please use the new Table components at "@circle-react-uikit/TableV2" */
export const TableRow = forwardRef<HTMLTableRowElement, TableRowProps>(
  ({ children, isSelectable = false, className, dataTestId, ...rest }, ref) => (
    <tr
      ref={ref}
      className={classnames(
        {
          "cursor-default": !isSelectable,
        },
        className,
      )}
      data-testid={dataTestId}
      {...rest}
    >
      {children}
    </tr>
  ),
);

TableRow.displayName = "TableRow";
