import { DateAvatar } from "@circle-react/components/Events/DateAvatar";
import { dateToTimeString } from "@circle-react/helpers/dateTimeHelpers/getLocalizedDate";
import type { EventBookmarkRecord } from "@circle-react/types/Bookmarks";
import { Typography } from "@circle-react-uikit/Typography";

export interface EventProps {
  bookmark: EventBookmarkRecord;
}

export const Event = ({ bookmark }: EventProps) => {
  if (!bookmark.bookmark_record) {
    return <>{}</>;
  }

  const {
    title,
    start_date: startDate,
    starts_at: startsAt,
    ends_at: endsAt,
    time_zone: timeZone,
    time_zone_abbreviations: timeZoneAbbreviations = "",
  } = bookmark.bookmark_record;

  const startTimeString = dateToTimeString(startsAt, timeZone);
  const endTimeString = dateToTimeString(endsAt, timeZone);

  const text = `${startTimeString} - ${endTimeString} ${timeZoneAbbreviations}`;

  return (
    <div className="flex items-center gap-3">
      <div>
        <DateAvatar dateString={startDate ?? ""} />
      </div>
      <div className="flex flex-col gap-0.5">
        <Typography.LabelSm weight="semibold">
          <span className="line-clamp-2 leading-5">{title}</span>
        </Typography.LabelSm>
        <Typography.LabelXs color="text-light">{text}</Typography.LabelXs>
      </div>
    </div>
  );
};
