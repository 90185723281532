import type { ReactNode } from "react";

export interface ListWrapperProps {
  children: ReactNode;
}

export const ListWrapper = ({ children }: ListWrapperProps) => (
  // h-[calc(100%-100px)] is the height of the list minus the height of the header, tabs and margin.
  <div
    className="mt-2 flex h-[calc(100%-100px)] w-full flex-col justify-between space-y-px overflow-y-auto px-2 pb-2"
    id="user-notifications-panel-header"
  >
    {children}
  </div>
);
