import { uniqueId } from "lodash";

interface SkeletonLoaderProps {
  count: number;
}

export const SkeletonLoader = ({ count }: SkeletonLoaderProps) => (
  <ul className="absolute inset-1/2 top-0 flex h-full w-full -translate-x-1/2 list-none items-center justify-center gap-2">
    {[...Array(count)].map((_, index) => (
      <li
        key={uniqueId(`skeleton-loader-${index}`)}
        className="h-8.5 bg-tertiary w-16 animate-pulse rounded-full"
      />
    ))}
  </ul>
);
