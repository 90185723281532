import type { QueryClient } from "react-query";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { notificationsApi } from "@/react/helpers/urlHelpers";
import type { ApiError } from "@circle-react/config/CustomErrors";
import {
  reactQueryDelete,
  reactQueryGet,
  reactQueryPost,
} from "@circle-react/helpers/backendRequestHelpers";
import type {
  NotificationAction,
  NotificationPage,
} from "@circle-react/types/Notification";
import { TABS } from "./NotificationContext";
import { NOTIFICATIONS_PER_PAGE } from "./constants";

export const ACTION_API_MAPPER: any = {
  mark_as_read: ({ id }: any) =>
    reactQueryPost(notificationsApi.markAsRead({ id })),
  mark_all_as_read: () => reactQueryPost(notificationsApi.markAllAsRead()),
  follow: ({ postId }: any) =>
    reactQueryPost(notificationsApi.follow({ postId })),
  unfollow: ({ postId, followerId }: any) =>
    reactQueryDelete(notificationsApi.unfollow({ postId, followerId })),
  archive: ({ id }: any) => reactQueryPost(notificationsApi.archive({ id })),
};

const NOTIFICATION_STALE_TIME = 60 * 1000;

export interface InvalidateNotificationsQueryCache {
  queryClient: QueryClient;
}

export const invalidateNotificationsQueryCache = ({
  queryClient,
}: InvalidateNotificationsQueryCache) => {
  void queryClient.invalidateQueries(["notifications"]);
};

export interface UseFetchNotifications {
  perPage?: number;
  notificationGroup?: string;
}

export const useFetchNotifications = ({
  perPage = NOTIFICATIONS_PER_PAGE,
  notificationGroup = TABS.INBOX,
}: UseFetchNotifications) => {
  const {
    isLoading,
    data: notifications,
    refetch,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery<NotificationPage>(
    ["notifications", notificationGroup],
    ({ pageParam = 1 }) =>
      reactQueryGet(
        notificationsApi.fetch({
          page: pageParam,
          perPage: String(perPage),
          notificationGroup,
        }),
      ),
    {
      staleTime: NOTIFICATION_STALE_TIME,
      getNextPageParam: lastPage =>
        lastPage.has_next_page ? lastPage.page + 1 : undefined,
    },
  );

  const lastPage = notifications?.pages[notifications?.pages.length - 1];
  const hasNextPage = !!lastPage?.has_next_page;

  return {
    isLoading,
    isFetchingNextPage,
    notifications,
    refetch,
    fetchNextPage,
    hasNextPage,
  };
};

export const useNotificationsCount = (
  communityId: any,
  setNotificationCount: any,
) =>
  useQuery(
    ["notifications", "count", communityId],
    () =>
      reactQueryGet(notificationsApi.fetchNotificationCount({ communityId })),
    {
      enabled: !!communityId,
      onSuccess: ({ new_notifications_count }) => {
        setNotificationCount(new_notifications_count);
      },
    },
  );

export const useResetNotificationCount = (setNotificationCount: any) => {
  const { mutate } = useMutation(
    () => reactQueryPost(notificationsApi.resetNotificationCount()),
    {
      onSuccess: data => {
        setNotificationCount(data.new_notifications_count);
      },
    },
  );
  return { mutate };
};

export const useNotificationsActions = () => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation<
    NotificationAction,
    ApiError,
    NotificationAction
  >(
    ({ action, id, postId, followerId }) =>
      ACTION_API_MAPPER[action]({ id, postId, followerId }),
    {
      onSuccess: () => invalidateNotificationsQueryCache({ queryClient }),
    },
  );

  return { mutate, isLoading };
};
