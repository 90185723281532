import type { NotificationActionType } from "@circle-react/types/Notification";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { NotificationTypeIcon } from "./NotificationTypeIcon";

export interface UserImageWithIconProps {
  src?: string;
  name: string;
  action: NotificationActionType;
}

export const UserImageWithIcon = ({
  src = "",
  name,
  action,
}: UserImageWithIconProps) => (
  <div className="relative mr-3 shrink-0">
    <UserImage src={src} name={name} size="8" />
    <NotificationTypeIcon action={action} />
  </div>
);
