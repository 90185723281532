import { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { AccordionItem } from "./AccordionItem";
import { AccordionContext } from "./useAccordionContext";
import "./styles.scss";

export const Accordion = ({ children, defaultActiveItemTitle }) => {
  const [activeItemTitle, setActiveItemTitle] = useState(
    children[0]?.props.title,
  );

  useEffect(() => {
    if (defaultActiveItemTitle) {
      setActiveItemTitle(defaultActiveItemTitle);
    }
  }, [defaultActiveItemTitle]);

  const setToggle = useCallback(
    title => {
      setActiveItemTitle(() => {
        if (activeItemTitle !== title) return title;
        return "";
      });
    },
    [setActiveItemTitle, activeItemTitle],
  );

  const value = useMemo(
    () => ({
      activeItemTitle,
      setToggle,
    }),
    [setToggle, activeItemTitle],
  );
  return (
    <AccordionContext.Provider value={value}>
      <div className="accordion">{children}</div>
    </AccordionContext.Provider>
  );
};

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  defaultActiveItemTitle: PropTypes.string,
};

Accordion.Item = AccordionItem;
