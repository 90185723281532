import type { ReactNode } from "react";
import { forwardRef } from "react";
import classNames from "classnames";
import "./styles.scss";

export interface TableProps {
  children: ReactNode;
  dataTestId?: string;
  className?: string;
  tableClassName?: string;
  variant?: "default" | "no-border" | "squared";
}

/** @deprecated Please use the new Table components at "@circle-react-uikit/TableV2" */
export const Table = forwardRef<HTMLTableElement, TableProps>(
  (
    { children, dataTestId, className, tableClassName, variant = "default" },
    ref,
  ) => (
    <div
      className={classNames("react-table", className)}
      data-testid={dataTestId}
    >
      <div className="react-table__container">
        <div
          className={classNames("react-table__wrapper", {
            "react-table__wrapper--no-border": variant === "no-border",
            "react-table__wrapper--squared": variant === "squared",
          })}
        >
          <table
            ref={ref}
            className={classNames("react-table__table", tableClassName)}
          >
            {children}
          </table>
        </div>
      </div>
    </div>
  ),
);

Table.displayName = "Table";
