export const focusFirstFocusableElement = (element: any) => {
  if (!element) return;

  const focusableElements = element.querySelectorAll(
    "button, [href], input, select, textarea, [tabindex]:not([tabindex='-1'])",
  );

  if (focusableElements.length > 0) {
    focusableElements[0].focus();
  }
};
