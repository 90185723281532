import { ThreeUserRow } from "@circleco/ui-kit";
import classNames from "classnames";
import { LabelXs } from "@/react/components/shared/uikit/Typography/components/Label/LabelXs";
import { toLocaleString } from "@circle-react/helpers/number";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

export interface Member {
  id: string;
  name: string;
  avatar_url: string;
}

interface User {
  id: string;
  name: string;
  src: string;
}

export interface MembersAvatarRowProps {
  members?: Member[];
  amountToShow?: number;
  totalCount?: number;
  bordered?: boolean;
}

export const MembersAvatarRow = ({
  members = [],
  amountToShow = 3,
  totalCount = 0,
  bordered = true,
}: MembersAvatarRowProps) => {
  const { isV3Enabled } = useIsV3();

  const users: User[] = members
    .slice(0, amountToShow)
    .map((member: Member): User => {
      const name = member?.name || "";
      const src = member?.avatar_url || "";
      const id = member?.id || "";
      return { id, name, src };
    });

  if (users.length === 0) {
    return null;
  }

  const plusCount = totalCount - users.length;
  const hasPlusCount = plusCount > 0;

  return (
    <div
      className={classNames(
        "isolate flex items-center rounded-lg px-5 py-2 md:justify-center",
        {
          "md:border-primary md:border md:p-1": bordered && !isV3Enabled,
          "gap-2 md:p-0": isV3Enabled,
        },
      )}
    >
      <div className="border-tertiary">
        <ThreeUserRow users={users} isFullRounded={isV3Enabled} />
      </div>
      {hasPlusCount && (
        <div
          className={classNames("flex", {
            "mx-1.5": !isV3Enabled,
          })}
        >
          <LabelXs weight="semibold" color="text-default">
            +{toLocaleString(plusCount)}
          </LabelXs>
        </div>
      )}
    </div>
  );
};
