import type { ReactNode } from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import "./styles.scss";

interface CollapsibleProps {
  title: ReactNode;
  children: ReactNode;
  noPadding?: boolean;
  noBorder?: boolean;
  defaultOpen?: boolean;
}

export const Collapsible = ({
  title,
  children,
  noPadding,
  noBorder,
  defaultOpen = false,
}: CollapsibleProps) => (
  <Disclosure defaultOpen={defaultOpen}>
    {({ open }) => (
      <div
        className={classNames("collapsible", {
          "collapsible--no-border": noBorder || !open,
        })}
      >
        <Disclosure.Button
          className={classNames("collapsible__title border-primary", {
            "collapsible__title--no-padding": noPadding,
            "border-b": !open,
          })}
        >
          <span>{title}</span>
          <ChevronDownIcon
            className={classNames("collapsible__arrow text-dark", {
              "collapsible__arrow--open": open,
            })}
          />
        </Disclosure.Button>
        <Disclosure.Panel className="collapsible__children" unmount={false}>
          {children}
        </Disclosure.Panel>
      </div>
    )}
  </Disclosure>
);
