import type { ComponentPropsWithRef, ReactNode } from "react";
import { forwardRef } from "react";
import classnames from "classnames";

export interface TableBodyProps extends ComponentPropsWithRef<"tbody"> {
  variant?: "default" | "no-divider";
  className?: string;
  children: ReactNode;
}

/** @deprecated Please use the new Table components at "@circle-react-uikit/TableV2" */
export const TableBody = forwardRef<HTMLTableSectionElement, TableBodyProps>(
  ({ variant = "default", className, children, ...rest }, ref) => (
    <tbody
      className={classnames(
        {
          ["bg-primary divide-primary divide-y"]: variant === "default",
          ["bg-primary"]: variant === "no-divider",
        },
        className,
      )}
      ref={ref}
      {...rest}
    >
      {children}
    </tbody>
  ),
);

TableBody.displayName = "TableBody";
