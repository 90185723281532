import type { ReactNode } from "react";
import classNames from "classnames";

interface HelloBarProps {
  children: ReactNode;
  className?: string;
  onExit?: () => void;
  exitText?: ReactNode;
  dataTestId?: string;
}

export const HelloBar = ({
  children,
  className,
  onExit,
  exitText,
  dataTestId = "trial-banner",
}: HelloBarProps) => (
  <div
    id={dataTestId}
    className={classNames(
      "bg-banner text-badge z-[3] hidden h-12 w-full items-center justify-center p-3 text-center font-semibold leading-5 lg:flex",
      { relative: !!exitText },
      className,
    )}
  >
    {children}
    {exitText && (
      <div className="absolute right-5">
        <button type="submit" onClick={onExit}>
          {exitText}
        </button>
      </div>
    )}
  </div>
);
