import type { Editor } from "@tiptap/react";
import classNames from "classnames";
import { InsertVariableButton } from "@circle-react-uikit/InsertVariableButton";

interface WorkflowDMMenuBarProps {
  editor: Editor | null;
  isFocused: boolean;
  dynamicVariables: any[];
}

export function WorkflowDMMenuBar({
  editor,
  isFocused,
  dynamicVariables,
}: WorkflowDMMenuBarProps) {
  if (!editor) {
    return null;
  }

  const menuIconClass = classNames(
    isFocused
      ? "text-default dark:text-dark"
      : "text-lightest dark:text-default",
  );

  return (
    <div>
      <InsertVariableButton
        className={menuIconClass}
        editor={editor}
        variables={dynamicVariables}
      />
    </div>
  );
}
