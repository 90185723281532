import type { PopperOptions } from "@circle-react-uikit/PopoverPortal";

export const DEFAULT_POPPER_OPTIONS: PopperOptions = {
  strategy: "fixed",
  placement: "bottom-end",
  modifiers: [
    {
      name: "offset",
      options: {
        offset: [0, 4],
      },
    },
  ],
};
