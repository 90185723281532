import type { ReactNode } from "react";
import classNames from "classnames";
import type { IconType } from "@circle-react-shared/Icon";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "../Typography";

export type ALERT_TYPES = "info" | "success" | "alert" | "warning";

const ICONS: { [k in ALERT_TYPES]: IconType } = {
  info: "20-info",
  success: "20-checkmark-circle-fill",
  alert: "20-alert",
  warning: "20-alert",
};

const STYLES: { [k in ALERT_TYPES]: string } = {
  info: "border-v2-info text-v2-info",
  success: "border-v2-success text-v2-success",
  alert: "border-v2-danger text-v2-danger",
  warning: "border-v2-warning text-v2-warning",
};

interface AlertProps {
  type?: ALERT_TYPES;
  icon?: ReactNode;
  title?: ReactNode;
  description?: ReactNode;
  showIcon?: boolean;
  className?: string;
  children?: ReactNode;
}

/**
 * Used to display alert information for the user
 * Currently only contains styles and icons for info. Add other types as necessary
 */
export const Alert = ({
  type = "info",
  icon,
  title,
  showIcon = true,
  description,
  className = "",
  children,
}: AlertProps) => (
  <div
    className={classNames(
      "rounded-lg border border-l-8 px-6 py-4",
      STYLES[type],
      className,
    )}
    role="alert"
  >
    <div className="flex w-full gap-x-3">
      {showIcon &&
        (icon ? (
          icon
        ) : (
          <Icon type={ICONS[type]} size={20} className="mt-1 !self-start" />
        ))}
      <div className="flex w-full flex-col gap-y-1">
        {title && (
          <Typography.LabelMd weight="semibold">{title}</Typography.LabelMd>
        )}
        {description && <Typography.LabelSm>{description}</Typography.LabelSm>}
        {children && <>{children}</>}
      </div>
    </div>
  </div>
);
