import { useNavigationLinks } from "@circle-react-uikit/HeaderV3/NavigationBar/hooks/useNavigationLinks";
import { MorePopover } from "./MorePopover";
import { NavigationLink } from "./NavigationItem";
import { SkeletonLoader } from "./SkeletonLoader";

export interface NavigationBarProps {
  isPreviewMode?: boolean;
}

export const NavigationBar = ({
  isPreviewMode = false,
}: NavigationBarProps) => {
  const { isLoadingLinks, visibleLinks, hiddenLinks, maxVisibleLinks } =
    useNavigationLinks({
      isDisabled: isPreviewMode,
    });

  if (isLoadingLinks) {
    return <SkeletonLoader count={maxVisibleLinks} />;
  }

  return (
    <ul
      className="absolute inset-1/2 top-0 flex h-full w-full -translate-x-1/2 list-none items-center justify-center gap-2"
      data-testid="header-navigation-bar"
    >
      {visibleLinks.map(({ linkTo, label, visibility, ...rest }) => (
        <li className="flex" key={linkTo}>
          <NavigationLink
            linkTo={linkTo}
            label={label}
            visibility={visibility}
            {...rest}
          />
        </li>
      ))}
      {hiddenLinks.length > 0 && (
        <li>
          <MorePopover items={hiddenLinks} isPreviewMode={isPreviewMode} />
        </li>
      )}
    </ul>
  );
};
