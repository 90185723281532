import { useContext } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { t } from "i18n-js";
import { Button } from "@circle-react-uikit/Button";
import { MultiStepPanelContext } from "./index";

export const Step = ({
  sequence,
  status,
  title,
  displayNext = false,
  displaySkip = false,
  displaySubmit = false,
  isNextStepDisabled,
  children,
}) => {
  const { onNextClick } = useContext(MultiStepPanelContext);

  return (
    <div
      key={title}
      className={classnames("multi-step-panel__step", {
        hidden: status !== "active",
      })}
    >
      {children}
      <div className="multi-step-panel__actions">
        {displayNext && (
          <Button
            variant="primary"
            large
            onClick={event => onNextClick(event, sequence)}
            {...(isNextStepDisabled ? { disabled: true } : {})}
          >
            {t("next")}
          </Button>
        )}
        {displaySkip && (
          <Button
            variant="secondary"
            large
            onClick={event => onNextClick(event, sequence)}
            marginLeft
            {...(isNextStepDisabled ? { disabled: true } : {})}
          >
            {t("skip")}
          </Button>
        )}
        {displaySubmit && (
          <Button
            variant="primary"
            large
            {...(isNextStepDisabled ? { disabled: true } : {})}
          >
            {t("finish")}
          </Button>
        )}
      </div>
    </div>
  );
};

Step.propTypes = {
  sequence: PropTypes.number,
  title: PropTypes.string,
  status: PropTypes.string,
  displayNext: PropTypes.bool,
  displaySubmit: PropTypes.bool,
  isNextStepDisabled: PropTypes.bool,
};
