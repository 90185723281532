import PropTypes from "prop-types";
import classnames from "classnames";
import { Table as TableV2 } from "@circle-react-uikit/TableV2";
import { useDataTableDataProvider } from "../Provider/DataTableDataProvider";

export const Table = ({ children, className }) => {
  const { isLoading } = useDataTableDataProvider();

  return (
    <TableV2
      className={classnames(className, {
        "cursor-wait": isLoading,
      })}
    >
      {children}
    </TableV2>
  );
};

Table.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
