export const HEADER_V2_1_DISABLED_ROUTES = [
  "/live/:roomSlug/not_supported",
  "/live/:roomSlug/record",
  "/live/:roomSlug/mobile",
  "/live/:roomSlug/public",
  "/live/:roomSlug",
  "/features/visual-refresh",
];

export const HEADER_V3_ROUTES = {
  members: "/members",
  events: "/events",
  courses: "/courses",
  leaderboard: "/leaderboard",
  home: "/feed",
} as const;
