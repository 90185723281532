import type { ReactNode } from "react";
import classNames from "classnames";

export interface TableHeadingRowProps {
  children: ReactNode;
  className?: string;
  isSticky?: boolean;
}

/** @deprecated Please use the new Table components at "@circle-react-uikit/TableV2" */
export const TableHeadingRow = ({
  isSticky,
  children,
  className,
}: TableHeadingRowProps) => (
  <thead
    className={classNames("bg-primary", className, {
      "sticky top-0 z-10": isSticky,
    })}
  >
    <tr>{children}</tr>
  </thead>
);
