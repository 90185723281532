import { useSmOrMdOrLgScreenMediaQuery } from "@/react/hooks/useMediaQuery";
import { Header } from "./Header";
import { BookmarkContents } from "./TabHeader";

export const BookmarksList = () => {
  const shouldDisplayHeader = useSmOrMdOrLgScreenMediaQuery();
  return (
    <>
      {!shouldDisplayHeader && <Header />}
      <BookmarkContents />
    </>
  );
};

export default BookmarksList;
