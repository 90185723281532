import classnames from "classnames";
import type { TableHeadProps } from "./interfaces";

export const TableHead = ({
  isSticky,
  className,
  children,
}: TableHeadProps) => (
  <thead
    className={classnames(
      {
        "bg-primary border-primary sticky top-0 border-t": isSticky,
      },
      className,
    )}
  >
    {children}
  </thead>
);
