import { colord } from "colord";
import { useIsCustomColorTheme } from "@circle-react/hooks/useIsCustomColorTheme";
import { THEME_MAP, useThemeContext } from "@circle-react/providers";

const headerBackgroundCssVar = "--color-community-header-background";

export const useHeaderColor = () => {
  const { customColors, isCustomTheme, currentTheme, currentAppearance } =
    useThemeContext();
  const customHeaderColor =
    customColors[currentAppearance]?.header_background_color || "#fff";

  const themeHeaderColor =
    THEME_MAP?.[currentTheme]?.[currentAppearance]?.[headerBackgroundCssVar] ||
    "#fff";

  return isCustomTheme ? colord(customHeaderColor) : colord(themeHeaderColor);
};

export const useCustomHeaderBorderColor = () => {
  const headerColor = useHeaderColor();

  if (headerColor.isDark()) {
    return headerColor.lighten(0.1).toHex();
  }
  return headerColor.darken(0.1).toHex();
};

export const useHeaderBorderStyles = () => {
  const borderColor = useCustomHeaderBorderColor();
  const isCustomColorTheme = useIsCustomColorTheme();

  if (isCustomColorTheme) {
    return {
      borderColor,
    };
  }
  return {};
};
