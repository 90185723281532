import { isUndefined } from "lodash";
import { KeyboardShortcut } from "./KeyboardShortcut";
import type { Shortcut } from "./constants";

export interface ShortcutProps {
  shortcut: Shortcut;
  isAdmin?: boolean;
  isModerator?: boolean;
}

export const ShortcutDisplay = ({
  shortcut,
  isAdmin = false,
  isModerator = false,
}: ShortcutProps) => {
  const { permissions, name, keys } = shortcut;

  if (isUndefined(permissions)) {
    return <KeyboardShortcut name={name} keys={keys} />;
  }

  if (
    (isAdmin && permissions.includes("admin")) ||
    (isModerator && permissions.includes("moderator"))
  ) {
    return <KeyboardShortcut name={name} keys={keys} />;
  }

  return null;
};
