import { useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import debounce from "lodash/debounce";
import { useDataTableDataProvider } from "../Provider/DataTableDataProvider";
import { useOnBottomReached } from "../hooks/useOnBottomReached";

export const ScrollableContainer = ({ className, children }) => {
  const tableContainerRef = useRef(null);
  const { onBottomReached } = useDataTableDataProvider();

  const { fetchMoreOnBottomReached } = useOnBottomReached({
    tableContainerRef,
    onBottomReached,
  });

  const debouncedScroll = debounce(fetchMoreOnBottomReached, 500);

  return (
    <div
      onScroll={e => {
        debouncedScroll(e.target);
      }}
      ref={tableContainerRef}
      className={classNames(className)}
    >
      {children}
    </div>
  );
};

ScrollableContainer.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  onBottomReached: PropTypes.func,
};
