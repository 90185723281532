import type { MessageBookmarkRecord } from "@circle-react/types/Bookmarks";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Typography } from "@circle-react-uikit/Typography";

export interface MessageProps {
  bookmark: MessageBookmarkRecord;
}

export const Message = ({ bookmark }: MessageProps) => {
  const {
    title,
    author_image: authorImage,
    author_name: authorName,
    body_text: bodyText,
  } = bookmark.bookmark_record ?? {};

  return (
    <div className="flex flex-col gap-2">
      <Typography.LabelXs weight="semibold">
        <span className="line-clamp-2 leading-5">{title}</span>
      </Typography.LabelXs>
      <div className="flex items-start gap-3">
        {authorName && (
          <div className="shrink-0 pt-0.5">
            <UserImage name={authorName} src={authorImage ?? ""} size="10" />
          </div>
        )}
        <div className="flex flex-col gap-1">
          <Typography.LabelSm weight="semibold">
            <span className="line-clamp-2 leading-5">{authorName}</span>
          </Typography.LabelSm>
          <Typography.LabelSm color="text-default">
            <span className="line-clamp-2 leading-5">{bodyText}</span>
          </Typography.LabelSm>
        </div>
      </div>
    </div>
  );
};
