import classNames from "classnames";
import { usePunditUserContext } from "@circle-react/contexts";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { ConditionallyRenderLoginSignupButtons } from "@circle-react-shared/ConditionallyRenderLoginSignupButtons";
import { DropdownWrapper } from "../DropdownWrapper";
import { useSettingsHeader } from "../useSettingsHeader";
import { Bookmarks } from "./Bookmarks";
import { ChatThreads } from "./ChatThreads";
import { DirectMessages } from "./DirectMessages";
import { Notifications } from "./Notifications";
import { ProfileSectionDropdown } from "./ProfileSectionDropdown";
import { SearchButton } from "./SearchButton";
import { VerticalSeparator } from "./VerticalSeparator";

export interface RightActionBlockProps {
  isRenderedOutsideSpace?: boolean;
}

export const RightActionBlock = ({
  isRenderedOutsideSpace,
}: RightActionBlockProps) => {
  const { currentCommunityMember, isViewOnlyMasquerading } =
    usePunditUserContext();
  const { isV3Enabled } = useIsV3();
  const { isSettingsRoute } = useSettingsHeader();

  if (!currentCommunityMember || isRenderedOutsideSpace) {
    return (
      <div className="absolute right-9 flex h-full items-center justify-end gap-6">
        {isV3Enabled && (
          <>
            <SearchButton />
            <VerticalSeparator />
          </>
        )}
        <ConditionallyRenderLoginSignupButtons />
      </div>
    );
  }

  if (isV3Enabled) {
    return (
      <DropdownWrapper
        className={classNames("z-30 gap-4 space-x-0 justify-self-end", {
          "cursor-not-allowed": isViewOnlyMasquerading,
        })}
        dataTestId="right-action-block"
      >
        {!isSettingsRoute && <SearchButton />}
        <div
          className={classNames("flex items-center", {
            "pointer-events-none": isViewOnlyMasquerading,
          })}
        >
          <Notifications />
          <DirectMessages />
          <ChatThreads />
          <Bookmarks />
        </div>
        <ProfileSectionDropdown />
      </DropdownWrapper>
    );
  }

  return (
    <div
      className={classNames("flex", {
        "cursor-not-allowed": isViewOnlyMasquerading,
      })}
    >
      <DropdownWrapper
        className={classNames("space-x-0 justify-self-end", {
          "pointer-events-none": isViewOnlyMasquerading,
        })}
        dataTestId="right-action-block"
      >
        <Notifications />
        <DirectMessages />
        <ChatThreads />
        <Bookmarks />
        <ProfileSectionDropdown />
      </DropdownWrapper>
    </div>
  );
};
