import { TranscriptsPlayer } from "../TranscriptsPlayer";
import { HtmlVideoPlayer } from "./HtmlVideoPlayer";

interface VideoPlayerProps {
  src: string;
  type: string;
  poster?: string;
  rounded?: boolean;
  isDownloadable?: boolean;
  mediaTranscriptId?: number;
  signedId?: string;
  variant?: "default" | "enhanced";
}

export const VideoPlayer = ({
  src,
  type,
  poster,
  rounded = true,
  isDownloadable = false,
  mediaTranscriptId,
  signedId,
  variant = "default",
}: VideoPlayerProps) => {
  if (variant === "enhanced") {
    return (
      <TranscriptsPlayer
        src={src}
        type={type}
        poster={poster}
        isDownloadable={isDownloadable}
        mediaTranscriptId={mediaTranscriptId}
        signedId={signedId}
      />
    );
  }

  return (
    <HtmlVideoPlayer
      src={src}
      type={type}
      poster={poster}
      rounded={rounded}
      mediaTranscriptId={mediaTranscriptId}
    />
  );
};
