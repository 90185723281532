import PropTypes from "prop-types";
import classNames from "classnames";

export const InfoBox = ({ children, className, ...rest }) => (
  <div
    className={classNames(
      "bg-tertiary text-dark w-full rounded-lg p-4",
      className,
    )}
    {...rest}
  >
    {children}
  </div>
);

InfoBox.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
