import classNames from "classnames";
import { useHeaderBorderStyles } from "../useHeaderColors";

export const VerticalSeparator = () => {
  const { borderColor: backgroundColor } = useHeaderBorderStyles();

  return (
    <div
      className={classNames("h-5 w-0.5 rounded-full", {
        "bg-tertiary": !backgroundColor,
      })}
      {...(backgroundColor && { style: { backgroundColor } })}
    />
  );
};
