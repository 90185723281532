import { useMemo } from "react";
import type { HeaderNavItem } from "@circle-react/components/SettingsApp/Navigation/hooks/useFetchHeaderNavItems";
import { useNavigationBar } from "../useNavigationBar";
import type { NavItem } from "../utils/transformHeaderNavItemsToNavItems";
import { transformHeaderNavItemsToNavItems } from "../utils/transformHeaderNavItemsToNavItems";

export interface UseTransformedNavItemsParams {
  headerNavItems: HeaderNavItem[];
  homeLinkTo: string;
  isCommunityAdmin: boolean;
  isDisabled: boolean;
  isHomeLinkActive: boolean;
}

export interface NavigationLink {
  isActive: boolean;
  isDisabled: boolean;
  isPresent: boolean;
  kind: NavItem["kind"];
  label: string;
  linkTo: string;
  openInNewWindow: boolean;
  visibility: NavItem["visibility"];
}

const isEnabled = (item: NavItem) => item.visibility === "enabled";
const isDisabled = (item: NavItem) => item.visibility === "disabled";
const isAdminOnly = (item: NavItem) => item.visibility === "admin_only";
const isItemOfKind = (kind: string) => (item: NavItem) => item.kind === kind;

const useAdminFilterLogic = () => {
  const { shouldShowLeaderboardLink } = useNavigationBar();

  return (item: NavItem) =>
    isItemOfKind("leaderboard")(item)
      ? shouldShowLeaderboardLink && !isDisabled(item)
      : !isDisabled(item);
};

const useMemberFilterLogic = () => {
  const {
    shouldShowMembersLink,
    shouldShowAllEventsLink,
    shouldShowCoursesLink,
    shouldShowLeaderboardLink,
  } = useNavigationBar();

  return (item: NavItem) => {
    if (isItemOfKind("members")(item)) {
      return shouldShowMembersLink && isEnabled(item);
    }
    if (isItemOfKind("events")(item)) {
      return shouldShowAllEventsLink && isEnabled(item);
    }
    if (isItemOfKind("courses")(item)) {
      return shouldShowCoursesLink && isEnabled(item);
    }
    if (isItemOfKind("leaderboard")(item)) {
      return shouldShowLeaderboardLink && isEnabled(item);
    }
    return !isDisabled(item) && !isAdminOnly(item);
  };
};

const mapNavItemToLink =
  (isDisabled: boolean, isHomeLinkActive: boolean) =>
  (item: NavItem): NavigationLink => ({
    linkTo: item.url,
    label: item.name,
    isPresent: true,
    isDisabled: isDisabled,
    isActive: isItemOfKind("home")(item) ? isHomeLinkActive : false,
    kind: item.kind,
    visibility: item.visibility,
    openInNewWindow: item.open_in_new_window,
  });

export const useNavItemsToLinks = ({
  headerNavItems,
  isDisabled,
  homeLinkTo,
  isHomeLinkActive,
  isCommunityAdmin,
}: UseTransformedNavItemsParams) => {
  const adminFilterLogic = useAdminFilterLogic();
  const memberFilterLogic = useMemberFilterLogic();

  return useMemo(() => {
    const navItems = transformHeaderNavItemsToNavItems(
      headerNavItems,
      homeLinkTo,
    );
    const filterLogic = isCommunityAdmin ? adminFilterLogic : memberFilterLogic;

    return navItems
      .filter(filterLogic)
      .map(mapNavItemToLink(isDisabled, isHomeLinkActive));
  }, [
    headerNavItems,
    isDisabled,
    homeLinkTo,
    isHomeLinkActive,
    isCommunityAdmin,
    adminFilterLogic,
    memberFilterLogic,
  ]);
};
