import PropTypes from "prop-types";
import classNames from "classnames";
import { Typography } from "@circle-react-uikit/Typography";

export const Label = ({ className, isVisible, children }) => (
  <span
    className={classNames(
      "flex h-4 items-center justify-center leading-none transition-opacity duration-200 ease-in-out",
      {
        "opacity-0": !isVisible,
        "opacity-100": isVisible,
      },
      className,
    )}
  >
    <Typography.LabelXs weight="semibold">{children}</Typography.LabelXs>
  </span>
);
Label.propTypes = {
  className: PropTypes.string,
  isVisible: PropTypes.bool,
  children: PropTypes.node,
};
