import PropTypes from "prop-types";
import { Header } from "./Header";
import { SortableTableHead } from "./SortableTableHead";

export const SortableHead = ({ className }) => (
  <Header
    className={className}
    renderHeader={({ header }) => (
      <SortableTableHead key={header.id} header={header} />
    )}
  />
);

SortableHead.propTypes = {
  className: PropTypes.string,
};
