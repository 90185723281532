import PropTypes from "prop-types";
import { t } from "i18n-js";
import { Avatar } from "@circle-react/components/Modals/MessagingModal/LeftSidebar/List/Item/Avatar";
import { Icon } from "@circle-react-shared/Icon";
import { Body } from "./Body";

export const Item = ({
  isDirect,
  otherParticipants,
  chatRoomName,
  timestamp,
  lastMessage,
  currentParticipant,
  unreadMessagesCount,
  currentCommunityMember,
  isPinned = false,
}) => (
  <div className="flex w-full items-start justify-between">
    <div
      className="flex items-center space-x-3"
      data-testid="direct-message-details"
    >
      <Avatar isDirect={isDirect} otherParticipants={otherParticipants} />
      <Body
        isDirect={isDirect}
        chatRoomName={chatRoomName}
        timestamp={timestamp}
        lastMessage={lastMessage}
        currentCommunityMember={currentCommunityMember}
        isPinned={isPinned}
      />
    </div>
    <div className="flex h-6 w-6 items-center justify-center">
      {currentParticipant.muted ? (
        <Icon
          type="mute-notifications"
          aria-label={t("header_v3.muted")}
          size={14}
        />
      ) : (
        unreadMessagesCount > 0 && (
          <span
            className="bg-messaging-info-blue inline-block h-2 w-2 rounded-full"
            data-testid="unread-messages-count"
          />
        )
      )}
    </div>
  </div>
);

Item.propTypes = {
  isDirect: PropTypes.bool.isRequired,
  otherParticipants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      avatar_url: PropTypes.string,
    }),
  ).isRequired,
  chatRoomName: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  lastMessage: PropTypes.shape({
    sender: PropTypes.shape({
      community_member_id: PropTypes.number,
      name: PropTypes.string,
    }),
    body: PropTypes.string,
    rich_text_body: PropTypes.shape({
      circle_ios_fallback_text: PropTypes.string,
    }),
  }).isRequired,
  currentParticipant: PropTypes.shape({
    muted: PropTypes.bool,
  }).isRequired,
  unreadMessagesCount: PropTypes.number.isRequired,
  currentCommunityMember: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  isPinned: PropTypes.bool,
};
