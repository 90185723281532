import PropTypes from "prop-types";
import classNames from "classnames";

export const Checkmark = ({ isChecked }) => (
  <span
    className="absolute left-0 block h-8 w-full p-1.5"
    aria-checked={isChecked}
  >
    <span className="relative flex h-full w-full items-center">
      <span
        aria-hidden="true"
        className={classNames(
          "pointer-events-none absolute inline-block h-5 w-5 rounded-full transition-[left] duration-200 ease-in-out",
          {
            "bg-v2-success left-[calc(100%-1.25rem)]": isChecked,
            "bg-dark left-0": !isChecked,
          },
        )}
      />
    </span>
  </span>
);

Checkmark.propTypes = {
  isChecked: PropTypes.bool,
};
