import type { ReactNode } from "react";
import classNames from "classnames";
import { useIsV3 } from "@circle-react/hooks/useIsV3";

export interface TableWrapperProps {
  children: ReactNode;
}

export const TableWrapper = ({ children, ...props }: TableWrapperProps) => {
  const { isV3Enabled } = useIsV3();

  return (
    <div
      className={classNames("px-6", {
        "lg:px-9": isV3Enabled,
      })}
      {...props}
    >
      {children}
    </div>
  );
};
