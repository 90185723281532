import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import type { BookmarkType } from "@circle-react/types/Bookmarks";
import { useBookmarks } from "@circle-react-shared/Bookmarks/hooks/useBookmarks";
import { Loader } from "@circle-react-uikit/Loader";
import { BookmarkItemWrapper } from "./BookmarkItemWrapper";
import { Actions } from "./Item/Actions";
import { ItemSelector } from "./Item/ItemSelector";
import { ListWrapper } from "./ListWrapper";
import { NoBookmarks } from "./NoBookmarks";

interface BookmarksListProps {
  activeTab?: BookmarkType;
}

export const BookmarksList = ({ activeTab = "post" }: BookmarksListProps) => {
  const { getBookmarks, hasNextPage, fetchNextPage, isLoading } = useBookmarks({
    bookmarkType: activeTab,
  });

  useEffect(() => {
    if (activeTab) {
      void getBookmarks();
    }
  }, [activeTab, getBookmarks]);

  const bookmarks = getBookmarks();

  if (isLoading) {
    return (
      <ListWrapper>
        <Loader center />
      </ListWrapper>
    );
  }

  if (!bookmarks.length) return <NoBookmarks activeTab={activeTab} />;

  return (
    <ListWrapper>
      <InfiniteScroll
        next={fetchNextPage}
        hasMore={!!hasNextPage}
        loader={<Loader center />}
        dataLength={bookmarks.length}
        style={{ overflow: "initial" }}
        scrollableTarget="user-bookmarks-panel-header"
      >
        {bookmarks.map(bookmark => (
          <div
            className="hover:bg-tertiary focus-visible:bg-tertiary group flex w-full items-start justify-between rounded-md px-4 py-3 transition-colors duration-200 ease-in-out"
            key={bookmark.id}
          >
            <BookmarkItemWrapper bookmark={bookmark} bookmarkType={activeTab}>
              <div className="flex items-start">
                <div
                  className="flex w-full flex-col space-y-1"
                  data-testid="bookmarks-details"
                >
                  <ItemSelector bookmark={bookmark} />
                </div>
              </div>
            </BookmarkItemWrapper>
            <div>
              <Actions
                recordId={bookmark.record_id}
                bookmarkId={bookmark.id}
                bookmarkType={activeTab}
                bookmarkRecord={bookmark.bookmark_record}
              />
            </div>
          </div>
        ))}
      </InfiniteScroll>
    </ListWrapper>
  );
};
