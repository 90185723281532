import type { ReactNode } from "react";
import classNames from "classnames";
import { t } from "i18n-js";
import RcDrawer from "rc-drawer";
import { IconButton } from "../HeaderV3/IconButton";
import { Footer } from "./Footer";
import { Header } from "./Header";
import "./styles.scss";

interface DrawerProps {
  open: boolean;
  title?: ReactNode;
  className?: string;
  variant?: "default" | "wide";
  width?: string;
  onChange?: (open?: boolean) => void;
  afterVisibleChange?: (open: boolean) => void;
  onClose?: () => void;
  children?: ReactNode;
  hideHeader?: boolean;
  drawerChildrenClassName?: string;
  fixedHeader?: boolean;
  noWrapperPadding?: boolean;
  maskClosable?: boolean;
}

const Drawer = ({
  open,
  title,
  className,
  hideHeader,
  variant = "default",
  onChange,
  afterVisibleChange,
  onClose,
  children,
  fixedHeader,
  noWrapperPadding,
  maskClosable = false,
  drawerChildrenClassName,
}: DrawerProps) => {
  if (!open) {
    return null;
  }

  return (
    <RcDrawer
      prefixCls="drawer"
      className={classNames(`drawer--${variant}`, className, {
        "drawer--no-padding": noWrapperPadding,
      })}
      open={open}
      level={null}
      placement="right"
      onChange={onChange}
      afterVisibleChange={afterVisibleChange}
      handler={null}
      onClose={onClose}
      maskClosable={maskClosable}
    >
      {!hideHeader && (
        <Header
          title={title}
          fixed={fixedHeader}
          addHeaderPadding={noWrapperPadding}
          Actions={
            <IconButton
              iconSize={24}
              name="24-close"
              onClick={onClose}
              ariaLabel={t("drawer.close_panel")}
              dataTestId="drawer-close-button"
            />
          }
        />
      )}
      {open && (
        <div className={`drawer__children ${drawerChildrenClassName}`}>
          {children}
        </div>
      )}
    </RcDrawer>
  );
};

Drawer.Header = Header;
Drawer.Footer = Footer;

export { Drawer };
