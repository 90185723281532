import PropTypes from "prop-types";
import { flexRender } from "@tanstack/react-table";
import isFunction from "lodash/isFunction";
import { Table } from "@circle-react-uikit/TableV2";

export const Head = ({ header, renderHeader }) => {
  if (isFunction(renderHeader)) {
    return renderHeader({ header });
  }

  return (
    <Table.Th key={header.id} className={header.column.columnDef.thClassName}>
      <Table.TableHeaderText
        className={header.column.columnDef.thInnerClassName}
      >
        {flexRender(header.column.columnDef.header, header.getContext())}
      </Table.TableHeaderText>
    </Table.Th>
  );
};

Head.propTypes = {
  header: PropTypes.object,
  renderHeader: PropTypes.func,
};
