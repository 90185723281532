import { Typography } from "@circle-react/components/shared/uikit/Typography";
import type { Notification } from "@circle-react/types/Notification";

export interface DisplayActionProps {
  notification: Notification;
}

export const DisplayAction = ({ notification }: DisplayActionProps) => (
  <Typography.BodySm as="span">{notification.display_action}</Typography.BodySm>
);
