import type { MouseEvent } from "react";
import { Button } from "@circle-react-uikit/Button";
import { Typography } from "@circle-react-uikit/Typography";

export interface EmptyStateProps {
  header: string;
  description?: string;
  primaryLabel?: string;
  onPrimaryClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  secondaryLabel?: string;
  onSecondaryClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  tertiaryLabel?: string;
  onTertiaryClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const EmptyState = ({
  header,
  description,
  primaryLabel,
  onPrimaryClick,
  secondaryLabel,
  onSecondaryClick,
  tertiaryLabel,
  onTertiaryClick,
}: EmptyStateProps) => {
  const hasPrimary = Boolean(onPrimaryClick && primaryLabel);
  const hasSecondary = Boolean(onSecondaryClick && secondaryLabel);
  const hasTertiary = Boolean(onTertiaryClick && tertiaryLabel);

  return (
    <div
      className="flex h-full w-full flex-col items-center justify-center gap-9 p-8 text-center"
      data-testid="empty-state"
    >
      <div className="flex max-w-lg flex-col gap-3">
        <Typography.TitleMd>{header}</Typography.TitleMd>
        {description && <Typography.BodyMd>{description}</Typography.BodyMd>}
      </div>
      <div className="flex gap-3">
        {hasPrimary && (
          <Button type="button" variant="circle" onClick={onPrimaryClick}>
            {primaryLabel}
          </Button>
        )}
        {hasSecondary && (
          <Button type="button" variant="secondary" onClick={onSecondaryClick}>
            {secondaryLabel}
          </Button>
        )}
      </div>
      {hasTertiary && (
        <Button type="button" variant="link" onClick={onTertiaryClick}>
          {tertiaryLabel}
        </Button>
      )}
    </div>
  );
};
