import type { Notification } from "@circle-react/types/Notification";
import { Selector } from "./Selector";

export interface ItemProps {
  notification: Notification;
}

export const Item = ({ notification }: ItemProps) => (
  <span
    className="w-[calc(100%-16px)] break-words leading-4"
    data-testid="notification-title"
  >
    {notification.notification_text_structure.map((entry, index) => {
      const isFirstEntry = index === 0;

      const isLastEntry =
        index === notification.notification_text_structure.length - 1;

      return (
        <>
          <Selector notification={notification} textStructure={entry} />
          {(isFirstEntry || !isLastEntry) && <span>&nbsp;</span>}
        </>
      );
    })}
  </span>
);
