import classnames from "classnames";
import type { TdProps } from "./interfaces";

export const Td = ({
  colSpan,
  className,
  onClick,
  children,
  hideBorders = false,
  ...rest
}: TdProps) => (
  <td
    colSpan={colSpan}
    className={classnames(
      "break-words p-3 align-top first:pl-0 last:pr-0",
      {
        "cursor-pointer": !!onClick,
        "border-primary border-b py-5": !hideBorders,
      },
      className,
    )}
    onClick={onClick}
    {...rest}
  >
    {children}
  </td>
);
