import type { ReactNode } from "react";
import classnames from "classnames";

export interface TableHeadingCellProps {
  children: ReactNode;
  className?: string;
  variant?: "actions" | "uppercase" | "no-x-padding";
}

/** @deprecated Please use the new Table components at "@circle-react-uikit/TableV2" */
export const TableHeadingCell = ({
  children,
  variant,
  className,
}: TableHeadingCellProps) => {
  const blockName = "react-table__heading-cell";
  const actionsModifierName = `${blockName}--actions`;
  const uppercaseModifierName = `${blockName}--uppercase`;

  return (
    <th
      scope="col"
      className={classnames(blockName, className, {
        [actionsModifierName]: variant === "actions",
        [uppercaseModifierName]: variant === "uppercase",
        "!px-0": variant === "no-x-padding",
      })}
    >
      {children}
    </th>
  );
};
