import { useEffect, useRef, useState } from "react";
import { isFunction, noop } from "lodash";

interface InsertVariableForInputProps {
  value: string;
}

export const useInsertVariableForInput = ({
  value,
}: InsertVariableForInputProps) => {
  const [changeHandler, setChangeHandler] = useState(noop);

  const inputRef = useRef(null);

  useEffect(() => {
    if (isFunction(changeHandler)) {
      setChangeHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- these are the relevant dependencies
  }, [value]);

  return {
    inputRef,
    setChangeHandler,
  };
};
