import PropTypes from "prop-types";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { Button } from "@circle-react-uikit/Button";
import { ToggleSwitch } from "@circle-react-uikit/ToggleSwitchWithLabel";
import { Typography } from "@circle-react-uikit/Typography";

export const SectionCard = ({
  children,
  dataTestId,
  onSubmit,
  title,
  submitButtonText,
  isSubmitDisabled,
  fullWidth,
  variant = "default",
  toggleSwitchProps = {},
  className,
  isFirstChild = false,
}) => {
  const isDefault = variant === "default";
  return (
    <div
      className={classNames(
        {
          "bg-primary border-primary rounded-lg border px-8 py-6": isDefault,
          "md:w-[624px]": isDefault && !fullWidth,
          "md:w-[720px]": !isDefault,
          "w-full": fullWidth,
        },
        className,
      )}
      data-testid={dataTestId}
    >
      <div className="flex flex-row items-center">
        <div>
          <Typography.TitleSm weight="font-bold">{title}</Typography.TitleSm>
        </div>
        {submitButtonText && (
          <div>
            <Button onClick={onSubmit} disabled={isSubmitDisabled}>
              {submitButtonText}
            </Button>
          </div>
        )}
        {!isEmpty(toggleSwitchProps) && (
          <ToggleSwitch
            field={toggleSwitchProps.field}
            value={toggleSwitchProps.value}
            onChange={toggleSwitchProps.onToggleChange}
          />
        )}
      </div>
      <div className={classNames({ "mt-1 md:mt-4": !isFirstChild })}>
        {children}
      </div>
    </div>
  );
};

SectionCard.propTypes = {
  children: PropTypes.any,
  dataTestId: PropTypes.string,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  submitButtonText: PropTypes.string,
  fullWidth: PropTypes.bool,
  isSubmitDisabled: PropTypes.bool,
  toggleSwitchProps: PropTypes.object,
  variant: PropTypes.oneOf(["default", "fullScreen"]),
  className: PropTypes.string,
};
