import PropTypes from "prop-types";
import classnames from "classnames";
import {
  Table,
  TableBody,
  TableCell,
  TableFootRow,
  TableHeadingCell,
  TableHeadingRow,
  TableRow,
} from "@circle-react/components/shared/uikit/Table";

/** @deprecated Please use the new DataTable at "@circle-react-uikit/DataTableV2" */
export const DataTable = ({
  columns,
  dataSource,
  className,
  footer,
  variant = "default",
  tableBodyVariant,
  hasStickyHeader,
  headerClassName,
  dataTestId,
}) => (
  <Table className={className} variant={variant} dataTestId={dataTestId}>
    <TableHeadingRow isSticky={hasStickyHeader} className={headerClassName}>
      {columns?.map(({ title, dataIndex, key, variant, className }) => (
        <TableHeadingCell
          key={key}
          variant={variant}
          {...(className && { className })}
        >
          {title || dataIndex}
        </TableHeadingCell>
      ))}
    </TableHeadingRow>
    <TableBody variant={tableBodyVariant}>
      {dataSource?.map(
        ({ key, isSelectable = false, rowClassName, ...data }) => (
          <TableRow
            key={key}
            isSelectable={isSelectable}
            className={rowClassName}
          >
            {columns?.map(
              ({ key: columnKey, dataIndex, tdClassName, shouldWrap }) => (
                <TableCell
                  key={`${key}-${columnKey}`}
                  onClick={data?.onClickColumns?.[dataIndex]}
                  className={classnames(tdClassName)}
                  shouldWrap={shouldWrap}
                >
                  {data?.[dataIndex]}
                </TableCell>
              ),
            )}
          </TableRow>
        ),
      )}
    </TableBody>
    {footer && <TableFootRow>{footer}</TableFootRow>}
  </Table>
);

DataTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      dataIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      variant: PropTypes.string,
    }),
  ).isRequired,
  dataSource: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      isSelectable: PropTypes.bool,
      rowClassName: PropTypes.string,
    }),
  ).isRequired,
  onClickColumns: PropTypes.object,
  className: PropTypes.string,
  footer: PropTypes.node,
  variant: PropTypes.string,
  tableBodyVariant: PropTypes.string,
  hasStickyHeader: PropTypes.bool,
  headerClassName: PropTypes.string,
  dataTestId: PropTypes.string,
};
