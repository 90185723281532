import { Typography } from "@circle-react/components/shared/uikit/Typography";
import type { Notification } from "@circle-react/types/Notification";

export interface ActorNameProps {
  notification: Notification;
}

export const ActorName = ({ notification }: ActorNameProps) => (
  <Typography.BodySm as="span" weight="font-semibold">
    {notification.actor_name}
  </Typography.BodySm>
);
