import { useRouteMatch } from "react-router-dom";
import { LogoLink } from "@circle-react/components/Spaces/SidebarV2/LogoTopSection/LogoLink";
import { usePunditUserContext } from "@circle-react/contexts";
import {
  getCommunityLogoUrlAndType,
  isPromoCodeEnabled,
  isSubscriptionExpired,
} from "@circle-react/helpers/communityHelpers";
import { useIsIframeEmbed } from "@circle-react/hooks/useIsIframeEmbed";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { useTailwindLgScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { useBackendProps } from "@circle-react/providers";
import { DropdownWrapper } from "./DropdownWrapper";
import { GlobalSearch } from "./GlobalSearch";
import { HeaderWrapper } from "./HeaderWrapper";
import { HeaderWrapperV3 } from "./HeaderWrapperV3";
import { LeftActionBlock } from "./LeftActionBlock";
import { MainCommunityDropdown } from "./MainCommunityDropdown";
import { NavigationBar } from "./NavigationBar";
import { RightActionBlock } from "./RightActionBlock";
import { SettingsSearchBar } from "./SettingsSearchBar";
import { HEADER_V2_1_DISABLED_ROUTES } from "./constants";
import { useKeyboardShortcuts } from "./useKeyboardShortcuts";
import { useSettingsHeader } from "./useSettingsHeader";

export interface HeaderV3Props {
  isRenderedOutsideSpace?: boolean;
}

export const HeaderV3 = ({ isRenderedOutsideSpace }: HeaderV3Props) => {
  const { isV3Enabled } = useIsV3();
  const { currentCommunity } = usePunditUserContext();
  const isLgScreen = useTailwindLgScreenMediaQuery();
  const isIframeEmbed = useIsIframeEmbed();
  const { url, type } = getCommunityLogoUrlAndType(currentCommunity);
  const isHeaderDisabledOnRoute = useRouteMatch(HEADER_V2_1_DISABLED_ROUTES);
  const isHeaderDisabled = !isLgScreen || isIframeEmbed;
  const { isSettingsRoute, isReactFlowWorkflowsRoute } = useSettingsHeader();
  const hasSubscriptionExpired = isSubscriptionExpired(currentCommunity);
  const isPromoCodeEnabledForCommunity = isPromoCodeEnabled(currentCommunity);
  const { minimal_layout: isMinimalLayout } = useBackendProps();

  useKeyboardShortcuts();

  const redirectToHostLocation = () => {
    window.location.href = window.location.origin;
  };

  if (
    isReactFlowWorkflowsRoute ||
    isHeaderDisabled ||
    hasSubscriptionExpired ||
    isPromoCodeEnabledForCommunity ||
    (isHeaderDisabledOnRoute && isHeaderDisabledOnRoute.isExact)
  ) {
    return null;
  }

  if (isMinimalLayout) {
    return (
      <HeaderWrapper isRenderedOutsideSpace>
        <DropdownWrapper>
          <LogoLink
            logoUrl={url ?? ""}
            logoType={type}
            communityName={currentCommunity?.name}
            onLogoClick={redirectToHostLocation}
          />
        </DropdownWrapper>
        <RightActionBlock isRenderedOutsideSpace={isRenderedOutsideSpace} />
      </HeaderWrapper>
    );
  }

  if (isSettingsRoute) {
    return isV3Enabled ? (
      <HeaderWrapperV3>
        <MainCommunityDropdown />
        <SettingsSearchBar />
        <RightActionBlock />
      </HeaderWrapperV3>
    ) : (
      <HeaderWrapper>
        <LeftActionBlock />
        <GlobalSearch />
        <RightActionBlock />
      </HeaderWrapper>
    );
  }

  if (isV3Enabled) {
    return (
      <HeaderWrapperV3>
        <MainCommunityDropdown />
        <NavigationBar />
        <RightActionBlock />
      </HeaderWrapperV3>
    );
  }

  return (
    <HeaderWrapper>
      <LeftActionBlock />
      <GlobalSearch />
      <RightActionBlock />
    </HeaderWrapper>
  );
};
