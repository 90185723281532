import { t } from "i18n-js";
import { useHistory } from "react-router-dom";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { newChatRoomPath } from "@circle-react/helpers/urlHelpers";
import { Button } from "@circle-react-shared/uikit/Button";
import { ListWrapper } from "../ListWrapper";

export const NoMessages = () => {
  const history = useHistory();
  return (
    <ListWrapper className="flex flex-col items-center justify-center !space-y-4">
      <div className="flex flex-col items-center justify-center space-y-2">
        <Typography.TitleMd weight="font-bold">
          {t("messaging.no_messages")}
        </Typography.TitleMd>
        <Typography.BodyMd>
          {t("header_v3.send_new_dm_description")}
        </Typography.BodyMd>
      </div>
      <Button
        className="mt-4"
        onClick={() => {
          history.push(newChatRoomPath());
        }}
      >
        {t("header_v3.send_new_dm")}
      </Button>
    </ListWrapper>
  );
};
