import type { ReactNode } from "react";
import classNames from "classnames";
import { useFocusWithin } from "@circle-react/hooks/utils/useFocusWithin";
import { useExpandableBlockData } from "./ExpandableBlockDataProvider";

interface ContainerProps {
  className?: string;
  children: ReactNode;
}

export const Container = ({ className, children }: ContainerProps) => {
  const { isOpen, shouldCloseOnClickOutside, onToggle } =
    useExpandableBlockData();

  const onBlurWithinHandler = () => {
    if (shouldCloseOnClickOutside && isOpen) {
      onToggle();
    }
  };

  const { focusWithinProps } = useFocusWithin({
    onBlurWithin: onBlurWithinHandler,
  });

  return (
    <div
      className={classNames(
        /* TODO Design Systems: Review color naming consistency: light/secondary/disabled etc */
        "bg-primary hover:shadow-xs w-full rounded-lg transition-colors duration-75",
        className,
      )}
      aria-expanded={isOpen}
      {...(shouldCloseOnClickOutside && focusWithinProps)}
    >
      {children}
    </div>
  );
};
