import PropTypes from "prop-types";
import { noop } from "lodash";
import { useFormContext } from "react-hook-form";
import { ButtonWithDisabled } from "@circle-react-uikit/ButtonWithDisabled";

export const AddRemoveToggle = ({
  name,
  rules = {},
  onChange = noop,
  className = "",
  addText = I18n.t("add"),
  removeText = I18n.t("remove"),
  ...props
}) => {
  const { register, watch, setValue, getValues } = useFormContext();
  const fieldValue = watch(name);

  const onClick = () => {
    const newValue = !getValues(name);
    setValue(name, newValue);
    onChange(newValue);
  };

  return (
    <>
      <input type="hidden" {...register(name, rules)} />
      <ButtonWithDisabled
        variant={fieldValue ? "secondary" : "primary"}
        className={className}
        onClick={onClick}
        {...props}
      >
        {fieldValue && removeText}
        {!fieldValue && addText}
      </ButtonWithDisabled>
    </>
  );
};

AddRemoveToggle.propTypes = {
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  onChange: PropTypes.func,
  className: PropTypes.string,
  addText: PropTypes.string,
  removeText: PropTypes.string,
};
