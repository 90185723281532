import type { ReactNode } from "react";

export interface TableFootRowProps {
  children: ReactNode;
}

/** @deprecated Please use the new Table components at "@circle-react-uikit/TableV2" */
export const TableFootRow = ({ children }: TableFootRowProps) => (
  <tfoot>{children}</tfoot>
);
