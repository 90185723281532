import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { dateStringToTimeAgo } from "@circle-react/helpers/dateTimeHelpers/timeAgo";

export interface TimeStampProps {
  createdAt: string;
}

export const TimeStamp = ({ createdAt }: TimeStampProps) => (
  <Typography.LabelXs color="text-default">
    {dateStringToTimeAgo(createdAt)}
  </Typography.LabelXs>
);
