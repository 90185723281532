import { useMemo } from "react";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import type { IconType } from "@circle-react-shared/Icon";

const userProfileIconsV2: Record<string, IconType> = {
  authenticationIcon: "16-lock-unlocked",
  billingIcon: "credit-card",
  paywallsAffiliatesIcon: "16-paywalls-affiliates",
  viewProfileIcon: "16-account",
  editProfileIcon: "16-edit",
  notificationIcon: "sidebar-bell",
  signOutIcon: "16-sign-out",
};

const userProfileIconsV3: Record<string, IconType> = {
  authenticationIcon: "20-locker-closed-v3",
  billingIcon: "20-card-v3",
  paywallsAffiliatesIcon: "20-affiliates-v3",
  viewProfileIcon: "20-user-v3",
  editProfileIcon: "20-edit-v3",
  notificationIcon: "20-bell-v3",
  signOutIcon: "20-sign-out-v3",
};

export const useUserProfileIcons = () => {
  const { isV3Enabled } = useIsV3();

  return useMemo(
    () => (isV3Enabled ? userProfileIconsV3 : userProfileIconsV2),
    [isV3Enabled],
  );
};
