import classNames from "classnames";
import { useExpandableBlockData } from "./ExpandableBlockDataProvider";

export interface ButtonProps {
  onClick?: () => void;
  className?: string;
  children: React.ReactNode;
}

export const Button = ({
  onClick: onClickProp,
  className,
  children,
}: ButtonProps) => {
  const { onToggle } = useExpandableBlockData();

  const onClickHandler = () => {
    onToggle();
    onClickProp?.();
  };

  return (
    <button
      type="button"
      className={classNames(
        "w-full cursor-pointer select-none px-8 py-6 text-left",
        className,
      )}
      onClick={onClickHandler}
    >
      {children}
    </button>
  );
};
