import classNames from "classnames";
import type { TableProps } from "./interfaces";

export const Table = ({ className, children }: TableProps) => (
  <table
    className={classNames(
      "bg-primary min-w-full border-separate border-spacing-0",
      className,
    )}
  >
    {children}
  </table>
);
