import type { ReactNode } from "react";
import classNames from "classnames";

interface FooterProps {
  sticky?: boolean;
  children?: ReactNode;
}

export const Footer = ({ sticky, children }: FooterProps) => (
  <div
    className={classNames("drawer__footer", {
      "drawer__footer--sticky": sticky,
    })}
  >
    {children}
  </div>
);
