import { useCallback, useEffect } from "react";

export const useOnBottomReached = ({
  tableContainerRef,
  onBottomReached,
  offsetInPixels = 300,
}) => {
  const fetchMoreOnBottomReached = useCallback(
    containerRefElement => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        // once the user has scrolled within ${offsetInPixels}px of the bottom of the table, fetch more data if there is any
        if (scrollHeight - scrollTop - clientHeight < offsetInPixels) {
          onBottomReached();
        }
      }
    },
    [onBottomReached, offsetInPixels],
  );

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached, tableContainerRef]);

  return {
    fetchMoreOnBottomReached,
  };
};
