import type { MouseEvent } from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import type {
  HeaderNavItemKind,
  HeaderNavItemVisibility,
} from "@circle-react/components/SettingsApp/Navigation/hooks/useFetchHeaderNavItems";

export interface NavigationLinkProps {
  linkTo: string;
  label: string;
  isActive?: boolean;
  isDisabled?: boolean;
  dataTestId?: string;
  isFullRounded?: boolean;
  onClick?: () => void;
  openInNewWindow?: boolean;
  kind: HeaderNavItemKind;
  visibility: HeaderNavItemVisibility;
}

export const NavigationLink = ({
  linkTo,
  label,
  isActive = false,
  isDisabled = false,
  dataTestId,
  isFullRounded = true,
  openInNewWindow = false,
  kind,
  visibility,
  onClick = () => {},
}: NavigationLinkProps) => {
  const shouldRenderCustomLink = kind === "home" && linkTo === "/";

  const onClickHandler = (event: MouseEvent<HTMLAnchorElement>) => {
    if (isDisabled) {
      event.preventDefault();
    } else {
      onClick?.();
    }
  };

  const baseLinkClasses = classNames(
    "text-xs-plus h-8.5 flex items-center px-4 py-2 font-medium transition-colors duration-150",
    "focus-visible:outline-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
    "hover:bg-c-header-hover text-c-header hover:text-c-header-active focus:text-c-header-active focus:bg-c-header-hover",
    {
      "!rounded-lg": !isFullRounded,
      "!rounded-full": isFullRounded,
    },
  );

  const linkProps = {
    onClick: onClickHandler,
    tabIndex: isDisabled ? -1 : 0,
    title: label,
    "data-testid": dataTestId,
    ...(openInNewWindow && { target: "_blank", rel: "noopener noreferrer" }),
  };

  const linkState =
    visibility === "admin_only" ? { visibility: "admin_only" } : undefined;

  if (kind !== "custom" && !shouldRenderCustomLink) {
    return (
      <NavLink
        to={{
          pathname: linkTo,
          state: linkState,
        }}
        {...linkProps}
        className={active =>
          classNames(baseLinkClasses, {
            "!bg-c-header-active hover:!bg-c-header-active !text-c-header-active hover:!text-c-header-active focus:!text-c-header-active":
              isActive ? isActive : active,
          })
        }
        aria-current={isActive ? "page" : undefined}
      >
        {label}
      </NavLink>
    );
  }

  return (
    <a
      href={linkTo}
      {...linkProps}
      className={classNames(baseLinkClasses, {
        "!bg-c-header-active hover:!bg-c-header-active !text-c-header-active hover:!text-c-header-active focus:!text-c-header-active":
          isActive !== undefined ? isActive : false,
      })}
    >
      {label}
    </a>
  );
};
