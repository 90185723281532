import type { ComponentPropsWithoutRef, ReactNode } from "react";

export interface DropdownItemProps {
  label: ReactNode;
  onClick?: ComponentPropsWithoutRef<"button">["onClick"];
  passThroughProps?: ComponentPropsWithoutRef<"button">;
  linkComponent?: ReactNode;
}

export const DropdownItem = ({
  label,
  onClick,
  passThroughProps,
  linkComponent,
}: DropdownItemProps) => (
  <li className="cursor-pointer">
    {linkComponent || (
      <button
        type="button"
        data-turbolinks="false"
        className="w-full text-left"
        {...passThroughProps}
        onClick={onClick}
      >
        {label}
      </button>
    )}
  </li>
);
