import PropTypes from "prop-types";
import { t } from "i18n-js";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { Icon } from "@circle-react-shared/Icon";

export const Body = ({
  isDirect,
  chatRoomName,
  timestamp,
  lastMessage,
  currentCommunityMember,
  isPinned,
}) => {
  const getMessageSenderName = () => {
    if (isDirect) return "";

    if (lastMessage.sender.community_member_id === currentCommunityMember?.id) {
      return `${t("messaging.you")}: `;
    }

    return `${lastMessage.sender.name}: `;
  };

  const renderMessageBody = () => {
    if (lastMessage.body) {
      return lastMessage.body;
    }

    return lastMessage.rich_text_body?.circle_ios_fallback_text;
  };

  return (
    <div>
      <div className="flex space-x-2">
        <Typography.BodySm truncate weight="font-medium">
          {chatRoomName}
        </Typography.BodySm>
        <Typography.BodyXs as="time" weight="font-medium" color="text-light">
          {timestamp}
        </Typography.BodyXs>
        {isPinned && (
          <Icon
            type="pin"
            aria-label={t("messaging.pinned_message")}
            aria-hidden={false}
          />
        )}
      </div>
      <div className="mt-0.5">
        <p className="text-default !line-clamp-1 flex max-w-[400px] items-center overflow-hidden hyphens-auto break-words text-sm">
          {`${getMessageSenderName()}${renderMessageBody()}`}
        </p>
      </div>
    </div>
  );
};

Body.propTypes = {
  isDirect: PropTypes.bool.isRequired,
  chatRoomName: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  lastMessage: PropTypes.shape({
    sender: PropTypes.shape({
      community_member_id: PropTypes.number,
      name: PropTypes.string,
    }),
    body: PropTypes.string,
    rich_text_body: PropTypes.shape({
      circle_ios_fallback_text: PropTypes.string,
    }),
  }).isRequired,
  currentCommunityMember: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
