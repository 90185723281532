import PropTypes from "prop-types";
import { noop } from "lodash";
import { useFormContext } from "react-hook-form";
import { ButtonWithDisabled } from "../ButtonWithDisabled/index";
import "./styles.scss";

export const Counter = ({
  name,
  rules = {},
  onChange = noop,
  className = "form-control",
  step = 1,
  min = 0,
  max,
}) => {
  const { register, setValue, getValues } = useFormContext();

  const onDecrement = () => {
    const oldValue = getValues(name);
    const newValue = parseInt(oldValue) - step;
    if (newValue < 0) return;
    setValue(name, newValue);
    onChange(newValue);
  };

  const onIncrement = () => {
    const oldValue = getValues(name);
    const value = parseInt(oldValue) + step;
    const newValue = max && max > 0 ? Math.min(value, step * max) : value;
    setValue(name, newValue);
    onChange(newValue);
  };

  return (
    <div className="react-counter">
      <ButtonWithDisabled variant="secondary-light" onClick={onDecrement}>
        <span>-</span>
      </ButtonWithDisabled>
      <input
        readOnly
        type="number"
        className={className}
        step={step}
        min={min}
        {...register(name, rules)}
      />
      <ButtonWithDisabled variant="secondary-light" onClick={onIncrement}>
        <span>+</span>
      </ButtonWithDisabled>
    </div>
  );
};

Counter.propTypes = {
  name: PropTypes.string,
  rules: PropTypes.object,
  onChange: PropTypes.func,
  className: PropTypes.string,
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
};
