export const KEYBOARD_SHORTCUTS_MAPPER = {
  basics: {
    open_search: "cmd+k, ctrl+k",
    open_keyboard_shortcuts_modal: "cmd+/, ctrl+/",
    toggle_appearance: "cmd+option+u, ctrl+alt+u",
    customize_theme: "option+c+t, alt+c+t",
    customize_notifications: "option+c+n, alt+c+n",
    edit_profile: "option+g+p, alt+g+p",
    write_a_post: "cmd+shift+p, ctrl+shift+p",
    view_your_profile: "option+g+v,alt+g+v",
  },
  navigation: {
    go_to_settings: "option+g+s, alt+g+s",
    go_to_home: "option+g+h, alt+g+h",
    go_to_members: "option+g+m, alt+g+m",
    go_to_courses: "option+g+c, alt+g+c",
    go_to_events: "option+g+e, alt+g+e",
    add_space: "option+shift+n, alt+shift+n",
    add_space_group: "option+shift+g, alt+shift+g",
    go_live: "option+g+l, alt+g+l",
  },
} as const;
