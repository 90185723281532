import PropTypes from "prop-types";
import classNames from "classnames";
import { Icon } from "@circle-react-shared/Icon";
import useAccordionContext from "./useAccordionContext";

export const AccordionItem = ({ children, title, isDone, showIcon }) => {
  const { activeItemTitle, setToggle } = useAccordionContext();
  const isActive = activeItemTitle === title;
  return (
    <div
      key={title}
      className={classNames("accordion__item", {
        "accordion__item--shown": isActive,
      })}
    >
      <button
        type="button"
        onClick={() => setToggle(title)}
        className="accordion__item-title"
      >
        {showIcon ? (
          <div className="flex items-center">
            <Icon
              className="accordion__item-check-icon"
              size={16}
              type={isDone ? "success-circle" : "circle-empty"}
            />
            <span className="text-left">{title}</span>
          </div>
        ) : (
          <span className="text-left">{title}</span>
        )}
        <Icon
          type="cheveron-down"
          className={classNames("accordion__item-title-icon", {
            "accordion__item-title-icon--rotated": isActive,
          })}
        />
      </button>
      <div
        className={classNames("accordion__item-content", {
          "accordion__item-content--shown": isActive,
        })}
      >
        {children}
      </div>
    </div>
  );
};

AccordionItem.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  isDone: PropTypes.bool,
  showIcon: PropTypes.bool,
};
