import PropTypes from "prop-types";
import { Table } from "@circle-react-uikit/TableV2";
import { useDataTableDataProvider } from "../Provider/DataTableDataProvider";
import { Head } from "./Head";

export const Header = ({ isSticky = true, className, renderHeader }) => {
  const { table } = useDataTableDataProvider();
  const headerGroups = table.getHeaderGroups();

  return (
    <Table.TableHead isSticky={isSticky} className={className}>
      {headerGroups.map(headerGroup => (
        <Table.Tr key={headerGroup.id}>
          {headerGroup.headers.map(header => (
            <Head key={header.id} header={header} renderHeader={renderHeader} />
          ))}
        </Table.Tr>
      ))}
    </Table.TableHead>
  );
};

Header.propTypes = {
  isSticky: PropTypes.bool,
  className: PropTypes.string,
  renderHeader: PropTypes.func,
};
