import classnames from "classnames";
import { mediaTranscriptPaths } from "@circle-react/helpers/urlHelpers";

interface HtmlVideoPlayerProps {
  src: string;
  type: string;
  poster?: string;
  rounded?: boolean;
  isInset?: boolean;
  mediaTranscriptId?: number;
}

export const HtmlVideoPlayer = ({
  src,
  type,
  poster,
  rounded = true,
  isInset = false,
  mediaTranscriptId,
}: HtmlVideoPlayerProps) => (
  <video
    className={classnames({
      "rounded-md": rounded && !isInset,
      "h-full w-full bg-black": isInset,
    })}
    controls
    controlsList="nodownload"
    preload="metadata"
    poster={poster}
  >
    <source src={src} type={type} />
    {mediaTranscriptId && (
      <track
        kind="captions"
        src={mediaTranscriptPaths.showVtt({ id: String(mediaTranscriptId) })}
      />
    )}
  </video>
);
