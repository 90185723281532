import type { BookmarkRecord } from "@circle-react/types/Bookmarks";
import { Comment } from "./Comment";
import { Event } from "./Event";
import { Lesson } from "./Lesson";
import { Message } from "./Message";
import { Post } from "./Post";

interface ItemSelectorProps {
  bookmark: BookmarkRecord;
}

export const ItemSelector = ({ bookmark }: ItemSelectorProps) => {
  switch (bookmark.bookmark_type) {
    case "post":
      return <Post bookmark={bookmark} />;
    case "comment":
      return <Comment bookmark={bookmark} />;
    case "event":
      return <Event bookmark={bookmark} />;
    case "lesson":
      return <Lesson bookmark={bookmark} />;
    case "message":
      return <Message bookmark={bookmark} />;
    default:
      return <>{}</>;
  }
};
