import { CircularProgress } from "@circle-react/components/shared/uikit/TipTap/AttachmentPreviews/CircularProgress";

export const Loader = () => (
  <span className="absolute w-full overflow-hidden">
    <span className="animate-spin-fast flex w-full items-center justify-center">
      <CircularProgress
        percentage={50}
        size={20}
        strokeWidth={1.75}
        backgroundClassName="text-secondary"
        foregroundCircleClassName="text-dark"
      />
    </span>
  </span>
);
