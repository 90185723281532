import type { ReactNode } from "react";

interface ListWrapperProps {
  children: ReactNode;
}

export const ListWrapper = ({ children }: ListWrapperProps) => (
  <div
    className="mt-2 flex h-[calc(100%-100px)] w-full flex-col justify-between space-y-px overflow-y-auto px-2 pb-2"
    id="user-bookmarks-panel-header"
  >
    {children}
  </div>
);
