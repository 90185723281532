import { CounterBadge } from "@circle-react/components/shared/CounterBadge";
import { usePunditUserContext } from "@circle-react/contexts";
import { roundedCount } from "@circle-react/helpers/notificationHelpers";
import { Tab } from "@circle-react-uikit/TabV2";
import { NotificationsList } from "./List";
import { TABS, useNotificationContext } from "./NotificationContext";
import { useNotificationsCount } from "./useHeaderNotificationsApi";

export const Body = () => {
  const { activeTab, setActiveTab, setNotificationCount, getTabLabel } =
    useNotificationContext();

  const { currentCommunity } = usePunditUserContext();

  const { data: counters } = useNotificationsCount(
    currentCommunity?.id,
    setNotificationCount,
  );

  const getCounterBadgedElement = (count: number) => {
    if (count > 0) {
      return <CounterBadge count={roundedCount(count)} />;
    }

    return null;
  };

  const tabsUnreadNotificationsCountMap: any = {
    [TABS.INBOX]: getCounterBadgedElement(counters?.new_inbox_count),
    [TABS.MENTIONS]: getCounterBadgedElement(counters?.new_mentions_count),
  };

  return (
    <Tab.Group
      className="overflow-hidden"
      selectedIndex={Object.values(TABS).indexOf(activeTab)}
    >
      <div className="border-primary border-b px-6">
        <Tab.List>
          {Object.values(TABS).map(tab => (
            <Tab
              key={tab}
              className="text-sm"
              onClick={() => {
                setActiveTab(tab);
              }}
            >
              <div className="flex items-center gap-1">
                {getTabLabel(tab)}
                {tabsUnreadNotificationsCountMap[tab]}
              </div>
            </Tab>
          ))}
        </Tab.List>
      </div>
      <NotificationsList />
    </Tab.Group>
  );
};
