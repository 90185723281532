import type { GalleryItem } from "@circle-react/types/Bookmarks";

export interface GalleryPreviewProps {
  gallery: GalleryItem[];
  attachmentCount: number;
}

export const GalleryPreview = ({
  gallery,
  attachmentCount,
}: GalleryPreviewProps) => {
  const maxImagesToShow = 5;
  const imagesToShow = gallery.slice(0, maxImagesToShow);
  const additionalImages = attachmentCount - maxImagesToShow;

  return (
    <div className="flex space-x-2">
      {imagesToShow.map((image, index) => (
        <div key={image.id} className="relative">
          <img
            loading="lazy"
            src={image.url}
            alt={image.alt_text || ""}
            className="aspect-square h-16 w-16 rounded-md object-cover"
          />
          {index === maxImagesToShow - 1 && additionalImages > 0 && (
            <div className="absolute inset-0 flex items-center justify-center rounded-lg bg-black/40">
              <span className="font-bold text-white">{`+${additionalImages}`}</span>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
