import type { ReactNode } from "react";
import { DropdownMenu_DEPRECATED, useDropdown } from "../../DropdownMenu";
import { DropdownItem } from "./DropdownItem";
import { IconButton } from "./IconButton";
import "./styles.scss";

interface MenuItem {
  label: string;
  linkComponent?: ReactNode;
  onClick?: () => void;
}

export interface IconDropdownMenuProps {
  renderButton?: () => ReactNode;
  menuItems: MenuItem[];
  dropdownSide?: "left" | "right";
  variant?: "default" | "v2";
  shouldCloseOnClickItem?: boolean;
}

export const IconDropdownMenu = ({
  renderButton,
  menuItems,
  dropdownSide = "right",
  shouldCloseOnClickItem = false,
}: IconDropdownMenuProps) => {
  const { open: isOpen, toggleDropdown, handleClose } = useDropdown(false);

  return (
    <div className="relative">
      <button
        type="button"
        onClick={toggleDropdown}
        className="dropdown-menu-icon"
      >
        <IconButton renderButton={renderButton} />
      </button>

      <DropdownMenu_DEPRECATED
        className={`dropdown-menu animate-right dropdown-menu-${dropdownSide}`}
        handleClose={handleClose}
        open={isOpen}
      >
        <div className="dropdown-menu__inside">
          <ul>
            {menuItems?.map(({ label, linkComponent, ...passThroughProps }) => {
              const onClick = () => {
                if (passThroughProps.onClick) {
                  passThroughProps.onClick();
                }
                if (shouldCloseOnClickItem) {
                  handleClose();
                }
              };

              return (
                <DropdownItem
                  key={label}
                  label={label}
                  passThroughProps={passThroughProps}
                  onClick={onClick}
                  linkComponent={linkComponent}
                />
              );
            })}
          </ul>
        </div>
      </DropdownMenu_DEPRECATED>
    </div>
  );
};
