import classnames from "classnames";
import isFunction from "lodash/isFunction";
import type { TrProps } from "./interfaces";

export const Tr = ({ onClick, className, children, ...rest }: TrProps) => {
  const hasOnClick = isFunction(onClick);

  return (
    <tr
      {...(hasOnClick && {
        onClick,
      })}
      className={classnames(
        "group",
        {
          "cursor-pointer": hasOnClick,
        },
        className,
      )}
      {...rest}
    >
      {children}
    </tr>
  );
};
