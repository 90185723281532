import type { ReactNode } from "react";
import classNames from "classnames";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { HeadlineTitle } from "./HeadlineTitle";

export interface ContainerProps {
  children: ReactNode;
  className?: string;
}

const Container = ({ children, className }: ContainerProps) => {
  const { isV3Enabled } = useIsV3();

  return (
    <div
      className={classNames("px-6 pb-1 pt-5", className, {
        "lg:px-9": isV3Enabled,
      })}
    >
      <div className="flex min-h-[1.75rem] items-center gap-4">{children}</div>
    </div>
  );
};

export const Headline = Object.assign(Container, {
  Title: HeadlineTitle,
});
