import type { ReactNode } from "react";
import { Icon } from "@circle-react-shared/Icon";

export interface IconButtonProps {
  renderButton?: () => ReactNode;
}

export const IconButton = ({ renderButton }: IconButtonProps) => {
  if (renderButton) {
    return <>{renderButton}</>;
  }
  return <Icon type="20-menu-dots-horizontal" size={20} aria-hidden="true" />;
};
