import classNames from "classnames";
import { Typography } from "@circle-react-uikit/Typography";
import type { TableHeaderTextProps } from "./interfaces";

export const TableHeaderText = ({
  children,
  className,
}: TableHeaderTextProps) => (
  <span className={classNames("flex", className)}>
    <Typography.LabelXxs leading="leading-tight" color="text-default">
      {children}
    </Typography.LabelXxs>
  </span>
);
