import type { ReactNode } from "react";
import classNames from "classnames";

interface HeaderProps {
  fixed?: boolean;
  title: ReactNode;
  addHeaderPadding?: boolean;
  Actions?: ReactNode;
}

export const Header = ({
  fixed,
  title,
  addHeaderPadding,
  Actions,
}: HeaderProps) => (
  <div
    className={classNames("drawer__header", {
      "drawer__header--fixed": fixed,
      "drawer__header--with-padding": addHeaderPadding,
    })}
  >
    <span className="header__title">{title}</span>
    <div className="header__actions">{Actions}</div>
  </div>
);
