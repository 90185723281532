import type { ComponentPropsWithoutRef } from "react";

type SpanProps = ComponentPropsWithoutRef<"span">;
export type MiddleDotProps = Omit<SpanProps, "children">;

const middleDot = "·";

export const MiddleDot = (props: MiddleDotProps) => (
  <span {...props}>{middleDot}</span>
);
