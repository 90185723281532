import { t } from "i18n-js";
import { Typography } from "@circle-react-uikit/Typography";

export const Header = () => (
  <div className="border-primary flex items-center justify-start border-b px-6 py-[18px]">
    <Typography.LabelLg weight="medium">
      {t("header_v3.chat_threads")}
    </Typography.LabelLg>
  </div>
);
