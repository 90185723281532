import PropTypes from "prop-types";
import classNames from "classnames";
import { useExpandableBlockData } from "./ExpandableBlockDataProvider";

export const Panel = ({ className, children }) => {
  const { isOpen } = useExpandableBlockData();

  return (
    <div
      className={classNames(
        "transition-all duration-150 ease-in",
        {
          "max-h-0 opacity-0": !isOpen,
          "max-h-full opacity-100": isOpen,
        },
        className,
      )}
    >
      <div className="p-8 pt-0">{children}</div>
    </div>
  );
};

Panel.propTypes = {
  as: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
