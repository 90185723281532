import { noop } from "lodash";
import { Link } from "react-router-dom";
import { getRouteWithPrevLocation } from "@/react/helpers/urlHelpers";
import { isIntendedOnNewTab } from "@circle-react/helpers/browserHelpers";
import { chatRoomPath } from "@circle-react/helpers/urlHelpers";

export const ItemWrapper = ({ children, onClose = noop, uuid }) => (
  <Link
    className="hover:bg-tertiary focus-visible:bg-tertiary flex cursor-pointer items-start justify-between rounded-md px-4 py-3 transition-colors duration-200 ease-in-out"
    onClick={e => {
      if (!isIntendedOnNewTab(e)) {
        onClose();
      }
    }}
    to={getRouteWithPrevLocation({ url: chatRoomPath({ uuid }) })}
    data-testid="chat-room-dm-list"
  >
    {children}
  </Link>
);
